import { Button, Col, Descriptions, Form, Input, Row, Select, Table, message, InputNumber, DatePicker,
  Tooltip,
} from "antd";
import { Content } from 'antd/es/layout/layout';
import { LayoutNav, UploadImage } from "components/layout.component";
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { ENABLE, REBATE_PERIOD } from 'enum/state';
import useAccount from 'hooks/account.hook';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { $get, $post } from 'services';
import {
  convertedToPercentage01 as $g,
  convertedToPercentage02 as $p2n,
  timeL2S,
  verify,
} from "utils/common";
import * as common from 'utils/common';
import { InfoCircleFilled } from "@ant-design/icons";
import { CKEditorComp } from "../../../components/promotion.component";
import { UploadFile } from "antd/lib/upload/interface";

// 全民代理設置

const PageMain: React.FC = () => {
  dayjs.extend(isSameOrBefore);
  const { permissions: $p } = useAccount();
  const [form] = Form.useForm();
  const [isEditDone, setIsEditDone] = useState(false);
  const { data: peopleAgentSettingInfo, isValidating: peopleAgentSettingInfoValidating } = $get({ url: 'api/bonus/peopleagent/info' });

  // 返水相關驗證
  const validator: any = async (d: any, val: number, i: number, rateName: string) => {
    const _val: number = Number(val);
    const min: number = 0;
    const max: number = 100;
    // 需大於等於min
    if (_val < min) return Promise.reject(() => 
      <p style={{ margin: 0 }}>{`${i18n.t('needBetween')}0~100`}</p>);
    // 需小於等於max
    else if (_val > max) return Promise.reject(() => 
      <p style={{ margin: 0 }}>{`${i18n.t('needBetween')}0~100`}</p>);
    else return Promise.resolve();
  }

  const validateOther: any = async (i: number, rateName: string, category: string) => {
    form.validateFields([`${rateName}-${i - 1}`, `${rateName}-${i + 1}`]);
  }

  // 等級相關驗證
  const levelValidator: any = async (d: any, val: number, i: number, rateName: string, category: string) => {
    const _val: number = Number(val);
    // 定義所有 level 的順序
    const levels = ['level1', 'level2', 'level3', 'level4', 'level5'];
    // 取得目前 level 在 levels 陣列中的索引
    const currentIndex = levels.indexOf(rateName);

    // 比較目前值是否小於下一個 level 的值
    if (currentIndex >= 0 && currentIndex < levels.length - 1) {
      const nextLevelName = `${levels[currentIndex + 1]}-${category}`; // 下一個 level 的名字
      const nextLevelValue: number = Number(form.getFieldValue(nextLevelName));
      // 如果目前值大於等於下一個 level 的值，回傳錯誤
      if (category!== "TotalPersionalDepositAmount" && (nextLevelValue && nextLevelValue!== 0) && _val > nextLevelValue) {
        return Promise.reject(() => (
          <p style={{ margin: 0 }}>
            {`${i18n.t('mustBeLessThanOrEqualTo')}${nextLevelValue}`}
          </p>
        ));
      }
      // 個人累積存款金額用
      if ( category=== "TotalPersionalDepositAmount" && (nextLevelValue && nextLevelValue!== 0) && _val >= nextLevelValue) {
        return Promise.reject(() => (
          <p style={{ margin: 0 }}>
            {`${i18n.t('mustBeLessThan')}${nextLevelValue}`}
          </p>
        ));
      }
    }

    // 比較目前值是否大於上一個 level 的值
    if (currentIndex > 0) {
      const prevLevelName = `${levels[currentIndex - 1]}-${category}`; // 上一個 level 的名字
      const prevLevelValue: number = Number(form.getFieldValue(prevLevelName));
      // 如果當前值小於等於上一個 level 的值，則回傳錯誤
      if (category!== "TotalPersionalDepositAmount" && (prevLevelValue && prevLevelValue!== 0) && _val < prevLevelValue) {
        return Promise.reject(() => (
          <p style={{ margin: 0 }}>
            {`${i18n.t('mustBeGreaterThanOrEqualTo')}${prevLevelValue}`}
          </p>
        ));
      }
      // 個人累積存款金額用
      if (category=== "TotalPersionalDepositAmount" && (prevLevelValue && prevLevelValue!== 0) && _val <= prevLevelValue) {
        return Promise.reject(() => (
          <p style={{ margin: 0 }}>
            {`${i18n.t('mustBeGreaterThan')}${prevLevelValue}`}
          </p>
        ));
      }

    }
    return Promise.resolve();
  };

  const levelValidateOther = async (i: number, rateName: string, category: string) => {
    const levels = ['level1', 'level2', 'level3', 'level4', 'level5'];
    // 取得目前 level 的索引
    const currentIndex = levels.indexOf(rateName);
    // 建構前一個與下一個 level 的欄位名
    const prevLevelName = currentIndex > 0 ? `${levels[currentIndex - 1]}-${category}` : null;
    const nextLevelName = currentIndex < levels.length - 1 ? `${levels[currentIndex + 1]}-${category}` : null;
    form.validateFields([prevLevelName, nextLevelName]);
  };

  // 控制等級欄位是否必填
  const [level2FieldRequired , setLevel2FieldRequired] = useState(false);
  const [level3FieldRequired , setLevel3FieldRequired] = useState(false);
  const [level4FieldRequired , setLevel4FieldRequired] = useState(false);
  const [level5FieldRequired , setLevel5FieldRequired] = useState(false);

  // 控制欄位是否disabled
  const [allStatusDisabled , setAllStatusDisabled] = useState(false);
  const [level2StatusDisabled , setLevel2StatusDisabled] = useState(false);
  const [level3StatusDisabled , setLevel3StatusDisabled] = useState(false);
  const [level4StatusDisabled , setLevel4StatusDisabled] = useState(false);
  const [level5StatusDisabled , setLevel5StatusDisabled] = useState(false);
  const handleAllStatus = (val: any) => {
    setAllStatusDisabled(val === ENABLE.disabled);
  };

  const handleLevel2Status = (val: any) => {
    setLevel2StatusDisabled(val === ENABLE.disabled);
    setLevel2FieldRequired(val === ENABLE.enabled)
  };

  const handleLevel3Status = (val: any) => {
    setLevel3StatusDisabled(val === ENABLE.disabled);
    setLevel3FieldRequired(val === ENABLE.enabled)
  };

  const handleLevel4Status = (val: any) => {
    setLevel4StatusDisabled(val === ENABLE.disabled);
    setLevel4FieldRequired(val === ENABLE.enabled)
  };
  const handleLevel5Status = (val: any) => {
    setLevel5StatusDisabled(val === ENABLE.disabled);
    setLevel5FieldRequired(val === ENABLE.enabled)
  };

  // 初次進入畫面判斷是否disabled 是否為必填
  useEffect(() => {
    if (peopleAgentSettingInfo?.Data) {
      const level2IsEnabled = form.getFieldValue("level2-IsEnabled");
      const level3IsEnabled = form.getFieldValue("level3-IsEnabled");
      const level4IsEnabled = form.getFieldValue("level4-IsEnabled");
      const level5IsEnabled = form.getFieldValue("level5-IsEnabled");
      setLevel2StatusDisabled(level2IsEnabled === ENABLE.disabled);
      setLevel3StatusDisabled(level3IsEnabled === ENABLE.disabled);
      setLevel4StatusDisabled(level4IsEnabled === ENABLE.disabled);
      setLevel5StatusDisabled(level5IsEnabled === ENABLE.disabled);

      setLevel2FieldRequired(level2IsEnabled === ENABLE.enabled)
      setLevel3FieldRequired(level3IsEnabled === ENABLE.enabled)
      setLevel4FieldRequired(level4IsEnabled === ENABLE.enabled)
      setLevel5FieldRequired(level5IsEnabled === ENABLE.enabled)
    }
  }, [peopleAgentSettingInfo,form.getFieldValue("level2-IsEnabled"),form.getFieldValue("level3-IsEnabled"),form.getFieldValue("level4-IsEnabled"),form.getFieldValue("level5-IsEnabled")]);

  // 控制狀態欄位是否顯示'停用'
  useEffect(() => {
    if (allStatusDisabled) {
      form.setFieldsValue({
        "level1-IsEnabled" : 0,
        "level2-IsEnabled" : 0,
        "level3-IsEnabled" : 0,
        "level4-IsEnabled" : 0,
        "level5-IsEnabled" : 0
      })
    }
    if(!allStatusDisabled){
      form.setFieldsValue({
        "level1-IsEnabled" : 1,
      })
    }
  }, [allStatusDisabled]);

  useEffect(() => {
    if (level2StatusDisabled) {
      form.setFieldsValue({
        "level3-IsEnabled" : 0,
        "level4-IsEnabled" : 0,
        "level5-IsEnabled" : 0
      })
    }
  }, [level2StatusDisabled]);

  useEffect(() => {
    if (level3StatusDisabled) {
      form.setFieldsValue({
        "level4-IsEnabled" : 0,
        "level5-IsEnabled" : 0
      })
    }
  }, [level3StatusDisabled]);

  useEffect(() => {
    if (level4StatusDisabled) {
      form.setFieldsValue({
        "level5-IsEnabled" : 0
      })
    }
  }, [level4StatusDisabled]);

  // 假資料
  const fakeData = [
    {
      Name: "全民代理等級名稱",
      key: 0
    },
    {
      Name: "全民代理等級名稱",
      key: 1
    },
    {
      Name: "狀態",
      key: 2
    },
    {
      Name: "有效會員條件",
      rule: "應達存款金額",
      key: 3
    },
    {
      Name: "晉級條件",
      rule: "個人累積存款金額",
      key: 4
    },
    {
      Name: "晉級條件",
      rule: "線下直屬會員人數",
      key: 5
    },
    {
      Name: "晉級條件",
      rule: "線下直屬會員累積存款金額",
      key: 6
    },
    {
      Name: "晉級條件",
      rule: "有效直屬會員人數",
      key: 7
    },
  ];
  const fakeData2 = [
    {
      Name: "返水獎勵",
      rule: "洗碼倍率",
      key: 0
    },
    {
      Name: "返水獎勵",
      rule: "遊戲大類 / 返水比例",
      key: 1
    },
    {
      Name: "返水獎勵",
      rule: "老虎機",
      key: 2
    },
    {
      Name: "返水獎勵",
      rule: "真人",
      key: 3
    },
    {
      Name: "返水獎勵",
      rule: "體育",
      key: 4
    },
    {
      Name: "返水獎勵",
      rule: "棋牌",
      key: 5
    },
    {
      Name: "返水獎勵",
      rule: "捕魚機",
      key: 6
    },
    {
      Name: "返水獎勵",
      rule: "彩票",
      key: 7
    },
    {
      Name: "返水獎勵",
      rule: "其他電子",
      key: 8
    },
    {
      Name: "返水獎勵",
      rule: "電競",
      key: 9
    },
    {
      Name: "返水獎勵",
      rule: "鬥雞",
      key: 10
    },
  ];

  const [imageData, setImageData] = useState<UploadFile<any>[]>([]);
  const [loading, setLoading] = useState(false);
  const [editorContent, setEditorContent] = useState<string>();

  // 時間相關
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);

  // 禁用今天之前的所有日期
  const disabledStartDate = (current: any) => {
    return current && current < dayjs().startOf('day');
  };

  // 禁用開始時間之前的日期並禁用今天之前的日期
  const disabledEndDate = (current: any) => {
    return (
      current &&
      (current < dayjs().startOf('day') || (startDate && current < dayjs(startDate).startOf('day')))
    );
  };

  // 禁用开始时间之前的时间
  const disabledStartTime = () => {
    const now = dayjs();
    return {
      disabledHours: () => {
        const hours = [];
        for (let i = 0; i < 24; i++) {
          if (i < now.hour()) {
            hours.push(i);
          }
        }
        return hours;
      },
      disabledMinutes: (selectedHour: any) => {
        const minutes = [];
        if (selectedHour === now.hour()) {
          for (let i = 0; i < 60; i++) {
            if (i < now.minute()) {
              minutes.push(i);
            }
          }
        }
        return minutes;
      },
      disabledSeconds: (selectedHour: any, selectedMinute: any) => {
        const seconds = [];
        if (selectedHour === now.hour() && selectedMinute === now.minute()) {
          for (let i = 0; i < 60; i++) {
            if (i < now.second()) {
              seconds.push(i);
            }
          }
        }
        return seconds;
      },
    };
  };

  // 禁用開始時間之前的結束時間，並禁用今天之前的時間
  const disabledEndTime = (endDate: any) => {
    const now = dayjs();
    const start = dayjs(startDate);

    return {
      disabledHours: () => {
        const hours = [];
        const currentEndDate = dayjs(endDate);

        // 如果结束日期是今天或之前，禁用今天之前的時間
        if (currentEndDate.isSameOrBefore(now, 'day')) {
          for (let i = 0; i < 24; i++) {
            if (i < now.hour()) {
              hours.push(i);
            }
          }
        }

        // 如果結束日期與開始日期相同，則停用開始時間之前的時間
        if (startDate && currentEndDate.isSame(start, 'day')) {
          for (let i = 0; i < 24; i++) {
            if (i < start.hour()) {
              hours.push(i);
            }
          }
        }
        return hours;
      },
      disabledMinutes: (selectedHour: any) => {
        const minutes = [];
        const currentEndDate = dayjs(endDate);

        // 如果結束日期是今天且小時相同，請停用目前時間之前的分鐘
        if (currentEndDate.isSame(now, 'day') && selectedHour === now.hour()) {
          for (let i = 0; i < 60; i++) {
            if (i < now.minute()) {
              minutes.push(i);
            }
          }
        }

        // 如果結束日期與開始日期相同且小時相同，則停用開始時間之前的分鐘
        if (startDate && currentEndDate.isSame(start, 'day') && selectedHour === start.hour()) {
          for (let i = 0; i < 60; i++) {
            if (i < start.minute()) {
              minutes.push(i);
            }
          }
        }
        return minutes;
      },
      disabledSeconds: (selectedHour: any, selectedMinute: any) => {
        const seconds = [];
        const currentEndDate = dayjs(endDate);

        //如果結束日期是今天且小時分鐘相同，請停用目前時間之前的秒數
        if (
          currentEndDate.isSame(now, 'day') &&
          selectedHour === now.hour() &&
          selectedMinute === now.minute()
        ) {
          for (let i = 0; i < 60; i++) {
            if (i < now.second()) {
              seconds.push(i);
            }
          }
        }

        // 如果结束日期与开始日期相同且小时分钟相同，禁用开始时间之前的秒数
        if (
          startDate &&
          currentEndDate.isSame(start, 'day') &&
          selectedHour === start.hour() &&
          selectedMinute === start.minute()
        ) {
          for (let i = 0; i < 60; i++) {
            if (i < start.second()) {
              seconds.push(i);
            }
          }
        }
        return seconds;
      },
    };
  };

  useEffect(() => {
    initData();
  }, [peopleAgentSettingInfo]);

  // 初始化資料
  const initData = () => {
    if (peopleAgentSettingInfo && peopleAgentSettingInfo.Data ) {
      const { Settings, CommissionMaximun,  CommissionMinimun} = JSON.parse(peopleAgentSettingInfo.Data?.BonusRuleSetting || '{}');
      const { StartDate, EndDate } = peopleAgentSettingInfo.Data;
      // 遍歷 Settings 動態設置表單
      const formValues: any = {
        promotionName: peopleAgentSettingInfo.Data?.Name,
        CommissionSettleCycle: Number(peopleAgentSettingInfo.CommissionSettleCycle || 0),
        Status: Number(peopleAgentSettingInfo.Data?.Status),
        levelSettlementTime: Number(peopleAgentSettingInfo.CommissionSettleCycle || 0),
        Minimum: CommissionMinimun,
        Maximum: CommissionMaximun,
        distributionMethod: peopleAgentSettingInfo.Data?.SettleMethod,
        sort: peopleAgentSettingInfo.Data?.Position,
        StartDate: dayjs(StartDate),
        internalContent: peopleAgentSettingInfo.Data?.Content
      };

      Settings?.forEach((setting: any, index: any) => {
        formValues[`level${index + 1}-Name`] = setting?.Name;
        formValues[`level${index + 1}-IsEnabled`] = setting?.IsEnabled? 1:0 ;
        formValues[`level${index + 1}-TotalDepositAmount`] = setting?.DirectMembership?.TotalDepositAmount;
        formValues[`level${index + 1}-TotalPersionalDepositAmount`] = setting?.Membership?.TotalPersionalDepositAmount;
        formValues[`level${index + 1}-DirectMemberCount`] = setting?.Membership?.DirectMemberCount;
        formValues[`level${index + 1}-TotalDirectMemberDepositAmount`] = setting?.Membership?.TotalDirectMemberDepositAmount;
        formValues[`level${index + 1}-ValidDirectMemberCount`] = setting?.Membership?.ValidDirectMemberCount;
        formValues[`level${index + 1}-ValidBetTimes`] = setting?.ValidBetTimes || 0;

        formValues[`level${index + 1}-PersionalCommission-SlotCommissionRate`] = $g(setting?.PersionalCommission?.SlotCommissionRate)|| 0;
        formValues[`level${index + 1}-PersionalCommission-LiveCommissionRate`] = $g(setting?.PersionalCommission?.LiveCommissionRate)|| 0;
        formValues[`level${index + 1}-PersionalCommission-SportsCommissionRate`] = $g(setting?.PersionalCommission?.SportsCommissionRate)|| 0;
        formValues[`level${index + 1}-PersionalCommission-PokerCommissionRate`] = $g(setting?.PersionalCommission?.PokerCommissionRate)|| 0;
        formValues[`level${index + 1}-PersionalCommission-FishingCommissionRate`] = $g(setting?.PersionalCommission?.FishingCommissionRate)|| 0;
        formValues[`level${index + 1}-PersionalCommission-LotteryCommissionRate`] = $g(setting?.PersionalCommission?.LotteryCommissionRate)|| 0;
        formValues[`level${index + 1}-PersionalCommission-EGamesCommissionRate`] = $g(setting?.PersionalCommission?.EGamesCommissionRate)|| 0;
        formValues[`level${index + 1}-PersionalCommission-ESportsCommissionRate`] = $g(setting?.PersionalCommission?.ESportsCommissionRate)|| 0;
        formValues[`level${index + 1}-PersionalCommission-CockfightCommissionRate`] = $g(setting?.PersionalCommission?.CockfightCommissionRate)|| 0;

        formValues[`level${index + 1}-DirectMemberCommission-SlotCommissionRate`] = $g(setting?.DirectMemberCommission?.SlotCommissionRate)|| 0;
        formValues[`level${index + 1}-DirectMemberCommission-LiveCommissionRate`] = $g(setting?.DirectMemberCommission?.LiveCommissionRate)|| 0;
        formValues[`level${index + 1}-DirectMemberCommission-SportsCommissionRate`] = $g(setting?.DirectMemberCommission?.SportsCommissionRate)|| 0;
        formValues[`level${index + 1}-DirectMemberCommission-PokerCommissionRate`] = $g(setting?.DirectMemberCommission?.PokerCommissionRate)|| 0;
        formValues[`level${index + 1}-DirectMemberCommission-FishingCommissionRate`] = $g(setting?.DirectMemberCommission?.FishingCommissionRate)|| 0;
        formValues[`level${index + 1}-DirectMemberCommission-LotteryCommissionRate`] = $g(setting?.DirectMemberCommission?.LotteryCommissionRate)|| 0;
        formValues[`level${index + 1}-DirectMemberCommission-EGamesCommissionRate`] = $g(setting?.DirectMemberCommission?.EGamesCommissionRate)|| 0;
        formValues[`level${index + 1}-DirectMemberCommission-ESportsCommissionRate`] = $g(setting?.DirectMemberCommission?.ESportsCommissionRate)|| 0;
        formValues[`level${index + 1}-DirectMemberCommission-CockfightCommissionRate`] = $g(setting?.DirectMemberCommission?.CockfightCommissionRate)|| 0;

      });

      form.setFieldsValue(formValues);

      setImageData([
        {
          uid: peopleAgentSettingInfo.Data?.Photo,
          name: peopleAgentSettingInfo.Data?.Photo,
          url: peopleAgentSettingInfo.Data?.Photo,
        },
      ])
      setEditorContent(peopleAgentSettingInfo.Data?.Content);
      if (EndDate === '0000-00-00 00:00:00') {
        form.setFieldValue('EndDate', null);
        setEndDate(null);
      } else {
        form.setFieldValue('EndDate', dayjs(EndDate));
        setEndDate(dayjs(EndDate));
      }
      setStartDate(dayjs(StartDate));
    }
    else{
      form.setFieldsValue({
        CommissionSettleCycle: peopleAgentSettingInfo?.CommissionSettleCycle || 0,
        levelSettlementTime: peopleAgentSettingInfo?.CommissionSettleCycle || 0
      })
    }
  }

  const onFinish = (formData: any) => {
    const settings = [];
    const levels = 5; // 假設有5個等級
    for (let i = 1; i <= levels; i++) {
      if (formData[`level${i}-IsEnabled`] === ENABLE.enabled) {
        const setting = {
          Id: i,
          Name: formData[`level${i}-Name`] || null,
          IsEnabled: formData[`level${i}-IsEnabled`] === 1,
          Membership: {
            TotalPersionalDepositAmount: Number(formData[`level${i}-TotalPersionalDepositAmount`]) || 0,
            DirectMemberCount: Number(formData[`level${i}-DirectMemberCount`]) || 0,
            TotalDirectMemberDepositAmount: Number(formData[`level${i}-TotalDirectMemberDepositAmount`]) || 0,
            ValidDirectMemberCount: Number(formData[`level${i}-ValidDirectMemberCount`]) || 0
          },
          ValidBetTimes: Number(formData[`level${i}-ValidBetTimes`]) || 1,
          DirectMembership: {
            TotalDepositAmount: Number(formData[`level${i}-TotalDepositAmount`]) || 0
          },
          PersionalCommission: {
            LiveCommissionRate: parseFloat($p2n(formData[`level${i}-PersionalCommission-LiveCommissionRate`])) || 0,
            SlotCommissionRate: parseFloat($p2n(formData[`level${i}-PersionalCommission-SlotCommissionRate`])) || 0,
            PokerCommissionRate: parseFloat($p2n(formData[`level${i}-PersionalCommission-PokerCommissionRate`])) || 0,
            EGamesCommissionRate: parseFloat($p2n(formData[`level${i}-PersionalCommission-EGamesCommissionRate`])) || 0,
            SportsCommissionRate: parseFloat($p2n(formData[`level${i}-PersionalCommission-SportsCommissionRate`])) || 0,
            ESportsCommissionRate: parseFloat($p2n(formData[`level${i}-PersionalCommission-ESportsCommissionRate`])) || 0,
            FishingCommissionRate: parseFloat($p2n(formData[`level${i}-PersionalCommission-FishingCommissionRate`])) || 0,
            LotteryCommissionRate: parseFloat($p2n(formData[`level${i}-PersionalCommission-LotteryCommissionRate`])) || 0,
            CockfightCommissionRate: parseFloat($p2n(formData[`level${i}-PersionalCommission-CockfightCommissionRate`])) || 0
          },
          DirectMemberCommission: {
            LiveCommissionRate: parseFloat($p2n(formData[`level${i}-DirectMemberCommission-LiveCommissionRate`])) || 0,
            SlotCommissionRate: parseFloat($p2n(formData[`level${i}-DirectMemberCommission-SlotCommissionRate`])) || 0,
            PokerCommissionRate: parseFloat($p2n(formData[`level${i}-DirectMemberCommission-PokerCommissionRate`])) || 0,
            EGamesCommissionRate: parseFloat($p2n(formData[`level${i}-DirectMemberCommission-EGamesCommissionRate`])) || 0,
            SportsCommissionRate: parseFloat($p2n(formData[`level${i}-DirectMemberCommission-SportsCommissionRate`])) || 0,
            ESportsCommissionRate: parseFloat($p2n(formData[`level${i}-DirectMemberCommission-ESportsCommissionRate`])) || 0,
            FishingCommissionRate: parseFloat($p2n(formData[`level${i}-DirectMemberCommission-FishingCommissionRate`])) || 0,
            LotteryCommissionRate: parseFloat($p2n(formData[`level${i}-DirectMemberCommission-LotteryCommissionRate`])) || 0,
            CockfightCommissionRate: parseFloat($p2n(formData[`level${i}-DirectMemberCommission-CockfightCommissionRate`])) || 0
          }
        };

        settings.push(setting);
      }
    }

    $post({
      url: 'api/bonus/peopleagent/upsert',
      send: {
        Name: formData.promotionName,
        Status: formData.Status,
        CommissionMinimun:Number(formData.Minimum),
        CommissionMaximun:Number(formData.Maximum),
        SettleMethod: formData.distributionMethod,
        Position: Number(formData.sort),
        StartDate: timeL2S(dayjs(startDate).format('YYYY-MM-DD HH:mm:ss')),
        EndDate: endDate ? timeL2S(dayjs(endDate).format('YYYY-MM-DD HH:mm:ss')) : null,
        Photo: imageData[0] ? [imageData[0].url] : [],
        Content: editorContent,
        Settings: settings
      },
      success: () => {
        message.success(i18n.t('editSuccess'));
        setIsEditDone(false);
      },
    }, setLoading)
  }

  // 第三區(生成等級條件JSX)
  const renderLevelConditionInput = (level: string, levelFieldRequired: any, handleLevelStatus: any, disabled: boolean, statusDisabled: any ) => (text: any ,record: any ,i: any) => {
    switch (i) {
      case 0:
        return <div>{level}</div>;
      case 1:
        return (
          <Form.Item
            name={`${level}-Name`}
            rules={[
              { required: levelFieldRequired, message: `${i18n.t('required')}` },
              {
                type: 'string',
                max: 10,
                message: `${i18n.t('peopleAgentLevelNameLimitedTo10Characters')}`
              }
            ]}
          >
            <Input
              placeholder={`${i18n.t('inputData')}`}
              disabled={disabled}
            />
          </Form.Item>
        );
      case 2:
        return (
          <Form.Item name={`${level}-IsEnabled`}>
            <Select
              style={{ width: "100%" }}
              placeholder={`${i18n.t("pleaseSelect")}`}
              options={common.enumToOptions(ENABLE)}
              disabled={statusDisabled}
              onChange={handleLevelStatus}
            />
          </Form.Item>
        );
      case 3:
        return (
          <Form.Item
            name={`${level}-TotalDepositAmount`}
            rules={[...verify({required: levelFieldRequired, point: 2 })]}
          >
            <Input
              disabled={disabled}
              placeholder={`${i18n.t("inputData")}`}
            />
          </Form.Item>
        );
      case 4:
        return (
          <Form.Item
            name={`${level}-TotalPersionalDepositAmount`}
            rules={[
              ...verify({required: levelFieldRequired, point: 2 , min: 1 }),
              { validator: (d, val) => levelValidator(d, val, i, level, "TotalPersionalDepositAmount") }
            ]}
          >
            <Input
              disabled={disabled}
              placeholder={`${i18n.t("inputData")}`}
              onChange={() => levelValidateOther(i, level, "TotalPersionalDepositAmount")}
            />
          </Form.Item>
        );
      case 5:
        return (
          <Form.Item
            name={`${level}-DirectMemberCount`}
            rules={[
              ...verify({required: levelFieldRequired,  point: 2 }),
              { validator: (d, val) => levelValidator(d, val, i, level, "DirectMemberCount") }
            ]}
          >
            <Input
              disabled={disabled}
              placeholder={`${i18n.t("inputData")}`}
              onChange={() => levelValidateOther(i, level, "DirectMemberCount")}
            />
          </Form.Item>
        );
      case 6:
        return (
          <Form.Item
            name={`${level}-TotalDirectMemberDepositAmount`}
            rules={[
              ...verify({required: levelFieldRequired, point: 2 }),
              { validator: (d, val) => levelValidator(d, val, i, level, "TotalDirectMemberDepositAmount") }
            ]}
          >
            <Input
              disabled={disabled}
              placeholder={`${i18n.t("inputData")}`}
              onChange={() => levelValidateOther(i, level, "TotalDirectMemberDepositAmount")}
            />
          </Form.Item>
        );
      case 7:
        return (
          <Form.Item
            name={`${level}-ValidDirectMemberCount`}
            rules={[
              ...verify({required: levelFieldRequired, point: 2 }),
              { validator: (d, val) => levelValidator(d, val, i, level, "ValidDirectMemberCount") }
            ]}
          >
            <Input
              disabled={disabled}
              placeholder={`${i18n.t("inputData")}`}
              onChange={() => levelValidateOther(i, level, "ValidDirectMemberCount")}
            />
          </Form.Item>
        );
      default:
        return null;
    }
  };

  // 第四區(生成返水獎勵JSX)
  const renderCommissionInput = (level: string, gameCategoryCashbackProportion: any, subcategory: any, disabled: boolean, levelFieldRequired?: any) => (text: any, record: any, i: any) => {
    const placeholder = i18n.t("inputData") as string; // Type assertion to ensure it's a string
    switch (i) {
      // 洗碼倍率 input
      case 0:
        return (
          <Form.Item
            name={`${level}-ValidBetTimes`}
            rules={verify({ required: levelFieldRequired })}
          >
            <Input
              placeholder={placeholder}
              disabled={disabled}
            />
          </Form.Item>
        );
      case 1:
        return <div className="font-w-600">{i18n.t(`${subcategory}`) as string}</div>;
      case 2:
        return (
          <Form.Item
            name={`${level}-${gameCategoryCashbackProportion}-SlotCommissionRate`}
            rules={[
              ...verify({ required: levelFieldRequired, point: 2 }),
              { validator: (d, val) => validator(d, val, i) },
            ]}
          >
            <Input
              placeholder={placeholder}
              addonAfter={"%"}
              disabled={disabled}
            />
          </Form.Item>
        );
      case 3:
        return (
          <Form.Item
            name={`${level}-${gameCategoryCashbackProportion}-LiveCommissionRate`}
            rules={[
              ...verify({ required: levelFieldRequired, point: 2 }),
              { validator: (d, val) => validator(d, val, i) },
            ]}
          >
            <Input
              placeholder={placeholder}
              addonAfter={"%"}
              disabled={disabled}
            />
          </Form.Item>
        );
      case 4:
        return (
          <Form.Item
            name={`${level}-${gameCategoryCashbackProportion}-SportsCommissionRate`}
            rules={[
              ...verify({ required: levelFieldRequired, point: 2 }),
              { validator: (d, val) => validator(d, val, i) },
            ]}
          >
            <Input
              placeholder={placeholder}
              addonAfter={"%"}
              disabled={disabled}
            />
          </Form.Item>
        );
      case 5:
        return (
          <Form.Item
            name={`${level}-${gameCategoryCashbackProportion}-PokerCommissionRate`}
            rules={[
              ...verify({ required: levelFieldRequired, point: 2 }),
              { validator: (d, val) => validator(d, val, i) },
            ]}
          >
            <Input
              placeholder={placeholder}
              addonAfter={"%"}
              disabled={disabled}
            />
          </Form.Item>
        );
      case 6:
        return (
          <Form.Item
            name={`${level}-${gameCategoryCashbackProportion}-FishingCommissionRate`}
            rules={[
              ...verify({ required: levelFieldRequired, point: 2 }),
              { validator: (d, val) => validator(d, val, i) },
            ]}
          >
            <Input
              placeholder={placeholder}
              addonAfter={"%"}
              disabled={disabled}
            />
          </Form.Item>
        );
      case 7:
        return (
          <Form.Item
            name={`${level}-${gameCategoryCashbackProportion}-LotteryCommissionRate`}
            rules={[
              ...verify({ required: levelFieldRequired, point: 2 }),
              { validator: (d, val) => validator(d, val, i) },
            ]}
          >
            <Input
              placeholder={placeholder}
              addonAfter={"%"}
              disabled={disabled}
            />
          </Form.Item>
        );
      case 8:
        return (
          <Form.Item
            name={`${level}-${gameCategoryCashbackProportion}-EGamesCommissionRate`}
            rules={[
              ...verify({ required: levelFieldRequired, point: 2 }),
              { validator: (d, val) => validator(d, val, i, "SlotCommissionRate") },
            ]}
          >
            <Input
              placeholder={placeholder}
              addonAfter={"%"}
              onChange={() => validateOther(i, "SlotCommissionRate")}
              disabled={disabled}
            />
          </Form.Item>
        );
      case 9:
        return (
          <Form.Item
            name={`${level}-${gameCategoryCashbackProportion}-ESportsCommissionRate`}
            rules={[
              ...verify({ required: levelFieldRequired, point: 2 }),
              { validator: (d, val) => validator(d, val, i) },
            ]}
          >
            <Input
              placeholder={placeholder}
              addonAfter={"%"}
              disabled={disabled}
            />
          </Form.Item>
        );
      case 10:
        return (
          <Form.Item
            name={`${level}-${gameCategoryCashbackProportion}-CockfightCommissionRate`}
            rules={[
              ...verify({ required: levelFieldRequired, point: 2 }),
              { validator: (d, val) => validator(d, val, i) },
            ]}
          >
            <Input
              placeholder={placeholder}
              addonAfter={"%"}
              disabled={disabled}
            />
          </Form.Item>
        );
      default:
        return null;
    }
  };

  const onCancel = () => {
    setIsEditDone(!isEditDone);
    initData();
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content className="ph-2">
        <Form form={form} component={false} onFinish={onFinish} layout="horizontal" >
          {(
            <Row gutter={[16, 16]} className="pb-1" >
              {/* 編輯列 */}
              <Col span={24} style={{ display: "flex", justifyContent: "end" }}>
                {
                  isEditDone && <>
                    <Button className="mr-1" onClick={onCancel}>{i18n.t('cancel')}</Button>
                    <Button type="primary" onClick={form.submit}>{i18n.t('confirm')}</Button>
                  </>
                }
                {
                  !isEditDone && $p('80102') &&
                  <Button type="primary" onClick={() => setIsEditDone(true)}>{i18n.t('edit')}</Button>
                }
              </Col>

              {/* 第一區 優惠名稱 */}
              <Col span={24}>
                <Form.Item name="promotionName"
                           label={i18n.t('promotionName')}
                           labelCol={{ span: 24 }}
                           wrapperCol={{ span: 24 }}
                           rules={[
                             { required: true, message: `${i18n.t('required')}` },
                             { type: 'string', max: 100, message: `${i18n.t('promotionActivityNameLimitedTo100Characters')}` }
                           ]}
                >
                  <Input className="w-24"
                         placeholder={`${i18n.t('inputData')}`}
                         disabled={!isEditDone}
                  />
                </Form.Item>
              </Col>
              {/* 第二區 */}
              <Col span={24}>
                <Descriptions layout="vertical" column={6} bordered size="small">
                  {/* 返水週期 */}
                  <Descriptions.Item style={{ width: "16.6%" }}
                                      label={<><span className="require">*</span><span className="font-w-600">{i18n.t("rebatePeriod")}</span></>}>
                    <Form.Item name="CommissionSettleCycle">
                      <Select
                        style={{ width: "100%" }}
                        disabled
                        options={common.enumToOptions(REBATE_PERIOD)}
                      />
                    </Form.Item>
                  </Descriptions.Item>
                  {/* 全民代理狀態 */}
                  <Descriptions.Item style={{ width: "16.6%" }}
                                      label={<><span className="require">*</span><span className="font-w-600">{`${i18n.t("allAgent")}${i18n.t("status")}`}</span></>}>
                    <Form.Item name="Status" rules={[...verify({required: true})]}>
                      <Select
                        style={{ width: "100%" }}
                        placeholder={`${i18n.t("pleaseSelect")}`}
                        options={common.enumToOptions(ENABLE)}
                        onChange={handleAllStatus}
                        disabled={!isEditDone}
                      />
                    </Form.Item>
                  </Descriptions.Item>
                  {/* 等級結算時間 */}
                  <Descriptions.Item style={{ width: "16.6%" }}
                                      label={<><span className="require">*</span><span className="font-w-600">{`${i18n.t("level")}${i18n.t("settlementTime")}`}</span></>}>
                    <Form.Item name="levelSettlementTime">
                      <Select
                        style={{ width: "100%" }}
                        disabled
                        options={common.enumToOptions(REBATE_PERIOD)}
                      />
                    </Form.Item>
                  </Descriptions.Item>
                  {/* 返水下限 */}
                  <Descriptions.Item style={{ width: "16.6%" }}
                                      label={<><span className="require">*</span><span className="font-w-600">{i18n.t("minimumRebate")}</span></>}>
                    <Form.Item name="Minimum"
                                rules={[...verify({ point: 2, min: 0.01, max: 999999999, isShowCompareNumber: true }),
                                  {
                                    validator: (_, value) => {
                                      const maxVal = form.getFieldValue('Maximum');
                                      if (value >= maxVal) {
                                        return Promise.reject(new Error(`${i18n.t("mustBeLessThan")} ${maxVal}`));
                                      }
                                      return Promise.resolve();
                                    },
                                  }
                                ]}>
                      <Input  disabled={!isEditDone || allStatusDisabled}
                              placeholder={`${i18n.t("inputData")}`} />
                    </Form.Item>
                  </Descriptions.Item>
                  {/* 返水上限 */}
                  <Descriptions.Item style={{ width: "16.6%" }}
                                      label={<><span className="require">*</span><span className="font-w-600">{i18n.t("maximumRebate")}</span></>}>
                    <Form.Item name="Maximum"
                                rules={[...verify({ point: 2, min: 0.01, max: 999999999, isShowCompareNumber: true }),
                                  {
                                    validator: (_, value) => {
                                      const minVal = form.getFieldValue('Minimum');
                                      if (value <= minVal) {
                                        return Promise.reject(new Error(`${i18n.t("mustBeGreaterThan")} ${minVal}`));
                                      }
                                      return Promise.resolve();
                                    },
                                  }
                                ]}>
                      <Input disabled={!isEditDone || allStatusDisabled} placeholder={`${i18n.t("inputData")}`} />
                    </Form.Item>
                  </Descriptions.Item>
                  {/* 派發方式 */}
                  <Descriptions.Item style={{ width: "16.6%" }}
                                      label={<><span className="require">*</span><span className="font-w-600">{i18n.t("distributionMethod")}</span></>}>
                    <Form.Item name="distributionMethod" rules={[...verify({required: true})]}>
                      <Select
                        style={{ width: "100%" }}
                        placeholder={i18n.t('pleaseSelect')}
                        disabled={!isEditDone || allStatusDisabled}
                        options={[
                          { value: 0, label: `${i18n.t('systemDistribution')}` },
                          { value: 1, label: `${i18n.t('manualDispatch')}` },
                        ]}
                      />
                    </Form.Item>
                  </Descriptions.Item>

                </Descriptions>
              </Col>
              {/* 第三區 */}
              {/* FIXME:待優化 */}
              <Col className="w-full">
                <Table
                  className="custom-table"
                  showHeader={false}
                  size="small"
                  loading={peopleAgentSettingInfoValidating}
                  bordered
                  dataSource={fakeData}
                  rowClassName={(record) => {
                    return record.key === 0 ? "bg-color-08 " : ""
                  }}
                  columns={[
                    {
                      width: 75,
                      rowScope: 'row',
                      onCell: (_: any, index: any) => {
                        const cellConfig: any = {
                          0: { colSpan: 2, rowSpan: 2 },
                          1: { colSpan: 0, rowSpan: 0 },
                          2: { colSpan: 2 },
                          4: { rowSpan: 4 },
                          5: { rowSpan: 0 },
                          6: { rowSpan: 0 },
                          7: { rowSpan: 0 }
                        };
                        return cellConfig[index] || {};
                      },
                      render: (_, record: any, i: number) => {
                        let textKey;
                        switch (i) {
                          case 0:
                          case 1:
                            textKey = 'peopleAgentLevelName';
                            break;
                          case 2:
                            textKey = 'status';
                            break;
                          case 3:
                            textKey = 'validMemberCriteria';
                            break;
                          case 4:
                          case 5:
                          case 6:
                          case 7:
                            textKey = 'upgradeConditions';
                            break;
                          default:
                            return null; // 其他情况返回空或处理其他逻辑
                        }
                        return (
                          <>
                            <span className="require">*</span>{i18n.t(textKey)}
                          </>
                        );
                      }
                    },
                    {
                      width: 75,
                      rowScope: 'row',
                      colSpan: 0,
                      onCell: (_: any, index: any) => {
                        const cellConfig: any = {
                          0: { colSpan: 0 },
                          1: { colSpan: 0 },
                          2: { colSpan: 0 },
                        };
                        return cellConfig[index] || {};
                      },
                      render: (_, record: any, i: number) => {
                        let textKey;
                        switch (i) {
                          case 3:
                            textKey = 'requiredDepositAmount';
                            break;
                          case 4:
                            textKey = 'personalAccumulatedDepositAmount';
                            break;
                          case 5:
                            textKey = 'numberOfDirectDownlineMembers';
                            break;
                          case 6:
                            textKey = 'accumulatedDepositDmountOfDownlineDirectMembers';
                            break;
                          case 7:
                            textKey = 'numberOfValidDirectMembers';
                            break;
                          default:
                            return null;
                        }
                        return (
                          <>
                            <div className="font-w-lighter">{i18n.t(textKey)}</div>
                          </>
                        );
                      }
                    },
                    // Level1
                    {
                      width: 150,
                      render: (_, record: any, i: number) => {
                        switch (i) {
                          case 0:
                            return <div>Level 1</div>;
                          case 1:
                            return (
                              <Form.Item
                                name={`level1-Name`}
                                rules={[
                                  { required: true, message: `${i18n.t('required')}` },
                                  {
                                    type: 'string',
                                    max: 10,
                                    message: `${i18n.t('peopleAgentLevelNameLimitedTo10Characters')}`
                                  }
                                ]}
                              >
                                <Input
                                  placeholder={`${i18n.t('inputData')}`}
                                  disabled={!isEditDone || allStatusDisabled}
                                />
                              </Form.Item>
                            );
                          case 2:
                            return (
                              <Form.Item name={`level1-IsEnabled`}>
                                <Select
                                  disabled
                                  style={{ width: "100%" }}
                                  placeholder={`${i18n.t("pleaseSelect")}`}
                                  options={common.enumToOptions(ENABLE)}
                                />
                              </Form.Item>
                            );
                          case 3:
                            return (
                              <Form.Item
                                name={`level1-TotalDepositAmount`}
                                rules={[...verify({ point: 2 })]}
                              >
                                <Input
                                  disabled={!isEditDone || allStatusDisabled}
                                  placeholder={`${i18n.t("inputData")}`}
                                />
                              </Form.Item>
                            );
                          case 4:
                            return (
                              <Form.Item
                                name={`level1-TotalPersionalDepositAmount`}
                                rules={[
                                  ...verify({ point: 2, min: 1 }),
                                  { validator: (d, val) => levelValidator(d, val, i, "level1", "TotalPersionalDepositAmount") }
                                ]}
                              >
                                <Input
                                  disabled={!isEditDone || allStatusDisabled}
                                  placeholder={`${i18n.t("inputData")}`}
                                  onChange={() => levelValidateOther(i, 'level1', "TotalPersionalDepositAmount")}
                                />
                              </Form.Item>
                            );
                          case 5:
                            return (
                              <Form.Item
                                name={`level1-DirectMemberCount`}
                                rules={[
                                  ...verify({ point: 2 }),
                                  { validator: (d, val) => levelValidator(d, val, i, "level1", "DirectMemberCount") }
                                ]}
                              >
                                <Input
                                  disabled={!isEditDone || allStatusDisabled}
                                  placeholder={`${i18n.t("inputData")}`}
                                  onChange={() => levelValidateOther(i, 'level1', "DirectMemberCount")}
                                />
                              </Form.Item>
                            );
                          case 6:
                            return (
                              <Form.Item
                                name={`level1-TotalDirectMemberDepositAmount`}
                                rules={[
                                  ...verify({ point: 2 }),
                                  { validator: (d, val) => levelValidator(d, val, i, "level1", "TotalDirectMemberDepositAmount") }
                                ]}
                              >
                                <Input
                                  disabled={!isEditDone || allStatusDisabled}
                                  placeholder={`${i18n.t("inputData")}`}
                                  onChange={() => levelValidateOther(i, 'level1', "TotalDirectMemberDepositAmount")}
                                />
                              </Form.Item>
                            );
                          case 7:
                            return (
                              <Form.Item
                                name={`level1-ValidDirectMemberCount`}
                                rules={[
                                  ...verify({ point: 2 }),
                                  { validator: (d, val) => levelValidator(d, val, i, "level1", "ValidDirectMemberCount") }
                                ]}
                              >
                                <Input
                                  disabled={!isEditDone || allStatusDisabled}
                                  placeholder={`${i18n.t("inputData")}`}
                                  onChange={() => levelValidateOther(i, 'level1', "ValidDirectMemberCount")}
                                />
                              </Form.Item>
                            );
                          default:
                            return null;
                        }
                      }
                    },
                    {
                      width: 150,
                      render: renderLevelConditionInput('level2', level2FieldRequired, handleLevel2Status, !isEditDone || allStatusDisabled || level2StatusDisabled, !isEditDone || allStatusDisabled )
                    },
                    // level3
                    {
                      width: 150,
                      render: renderLevelConditionInput('level3', level3FieldRequired, handleLevel3Status, !isEditDone || allStatusDisabled || level2StatusDisabled || level3StatusDisabled, !isEditDone || allStatusDisabled || level2StatusDisabled )
                    },
                    // level4
                    {
                      width: 150,
                      render: renderLevelConditionInput('level4', level4FieldRequired, handleLevel4Status, !isEditDone || allStatusDisabled || level2StatusDisabled || level3StatusDisabled || level4StatusDisabled, !isEditDone || allStatusDisabled || level2StatusDisabled || level3StatusDisabled )
                    },
                    // level5
                    {
                      width: 150,
                      render: renderLevelConditionInput('level5', level5FieldRequired, handleLevel5Status, !isEditDone || allStatusDisabled || level2StatusDisabled || level3StatusDisabled || level4StatusDisabled || level5StatusDisabled, !isEditDone || allStatusDisabled || level2StatusDisabled || level3StatusDisabled || level4StatusDisabled )
                    },
                  ]}
                  pagination={false}
                />
              </Col>
              {/* 第四區 返水獎勵 */}
              <Col className="w-full">
                <Table
                  showHeader={false}
                  size="small"
                  loading={peopleAgentSettingInfoValidating}
                  bordered
                  dataSource={fakeData2}
                  columns={[
                    {
                      rowScope:'row',
                      width: 75,
                      onCell: (_, index) => {
                        if (index === 0 ) {
                          return {
                            rowSpan: 11
                          };
                        }
                        return {
                          rowSpan: 0,
                        };
                      },
                      render: (_, record: any, i: number) => {
                        let textKey;
                        switch (i) {
                          case 0:
                          case 1:
                          case 2:
                          case 3:
                          case 4:
                          case 5:
                          case 6:
                          case 7:
                          case 8:
                          case 9:
                          case 10:
                            textKey = 'rebateReward';
                            break;
                          default:
                            return null;
                        }
                        return (
                          <>
                            <span className="require">*</span>{i18n.t(textKey)}
                          </>
                        );
                      }
                    },
                    {
                      rowScope:'row',
                      width: 75,
                      render: (_, record: any, i: number) => {
                        let textKey;
                        switch (i) {
                          case 0:
                            textKey = 'rolloverRate';
                            break;
                          case 1:
                            textKey = 'gameCategoryCashbackProportion';
                            break;
                          case 2:
                            textKey = 'SLOT';
                            break;
                          case 3:
                            textKey = 'LIVE';
                            break;
                          case 4:
                            textKey = 'SPORTS';
                            break;
                          case 5:
                            textKey = 'POKER';
                            break;
                          case 6:
                            textKey = 'FISHING';
                            break;
                          case 7:
                            textKey = 'LOTTERY';
                            break;
                          case 8:
                            textKey = 'EGAMES';
                            break;
                          case 9:
                            textKey = 'ESPORTS';
                            break;
                          case 10:
                            textKey = 'COCKFIGHT';
                            break;
                          default:
                            return null;
                        }
                        return i === 0 || i === 1 ? (
                          <div>{i18n.t(textKey)}</div>
                        ) : (
                          <div className="font-w-lighter">{i18n.t(textKey)}</div>
                        );
                      }
                    },
                    {
                        width: 75,
                        onCell: (_, index) => {
                          if (index === 0 ) {
                            return {
                              colSpan: 2
                            };
                          }
                          return {
                          };
                        },
                      render: renderCommissionInput('level1', 'PersionalCommission', 'personalRebate', !isEditDone || allStatusDisabled)
                    },
                    {
                      width: 75,
                      onCell: (_, index) => {
                        if (index === 0 ) {
                          return {
                            colSpan: 0
                          };
                        }
                        return {
                        };
                      },
                      render: renderCommissionInput('level1', 'DirectMemberCommission','rebateForDirectDownlineValidMembers', !isEditDone || allStatusDisabled)
                    },
                    // level2返水獎勵
                    {
                      width: 75,
                      onCell: (_, index) => {
                        if (index === 0 ) {
                          return {
                            colSpan: 2
                          };
                        }
                        return {
                        };
                      },
                      render: renderCommissionInput('level2', 'PersionalCommission','personalRebate', !isEditDone || allStatusDisabled || level2StatusDisabled, level2FieldRequired)
                    },
                    {
                      width: 75,
                      onCell: (_, index) => {
                        if (index === 0 ) {
                          return {
                            colSpan: 0
                          };
                        }
                        return {
                        };
                      },
                      render: renderCommissionInput('level2', 'DirectMemberCommission','rebateForDirectDownlineValidMembers', !isEditDone || allStatusDisabled || level2StatusDisabled, level2FieldRequired)
                    },
                    // level3返水獎勵
                    {
                      width: 75,
                      onCell: (_, index) => {
                        if (index === 0 ) {
                          return {
                            colSpan: 2
                          };
                        }
                        return {
                        };
                      },
                      render: renderCommissionInput('level3', 'PersionalCommission','personalRebate', !isEditDone || allStatusDisabled || level2StatusDisabled || level3StatusDisabled, level3FieldRequired)
                    },
                    {
                      width: 75,
                      onCell: (_, index) => {
                        if (index === 0 ) {
                          return {
                            colSpan: 0
                          };
                        }
                        return {
                        };
                      },
                      render: renderCommissionInput('level3', 'DirectMemberCommission','rebateForDirectDownlineValidMembers', !isEditDone || allStatusDisabled || level2StatusDisabled || level3StatusDisabled, level3FieldRequired)
                    },
                    // level4返水獎勵
                    {
                      width: 75,
                      onCell: (_, index) => {
                        if (index === 0 ) {
                          return {
                            colSpan: 2
                          };
                        }
                        return {
                        };
                      },
                      render: renderCommissionInput('level4', 'PersionalCommission','personalRebate', !isEditDone || allStatusDisabled || level2StatusDisabled || level3StatusDisabled || level4StatusDisabled, level4FieldRequired)
                    },
                    {
                      width: 75,
                      onCell: (_, index) => {
                        if (index === 0 ) {
                          return {
                            colSpan: 0
                          };
                        }
                        return {
                        };
                      },
                      render: renderCommissionInput('level4', 'DirectMemberCommission','rebateForDirectDownlineValidMembers', !isEditDone || allStatusDisabled || level2StatusDisabled || level3StatusDisabled || level4StatusDisabled, level4FieldRequired)
                    },
                    // level5返水獎勵
                    {
                      width: 75,
                      onCell: (_, index) => {
                        if (index === 0 ) {
                          return {
                            colSpan: 2
                          };
                        }
                        return {
                        };
                      },
                      render: renderCommissionInput('level5', 'PersionalCommission','personalRebate', !isEditDone || allStatusDisabled || level2StatusDisabled || level3StatusDisabled || level4StatusDisabled || level5StatusDisabled, level5FieldRequired)
                    },
                    {
                      width: 75,
                      onCell: (_, index) => {
                        if (index === 0 ) {
                          return {
                            colSpan: 0
                          };
                        }
                        return {
                        };
                      },
                      render: renderCommissionInput('level5', 'DirectMemberCommission','rebateForDirectDownlineValidMembers', !isEditDone || allStatusDisabled || level2StatusDisabled || level3StatusDisabled || level4StatusDisabled || level5StatusDisabled, level5FieldRequired)
                    },
                  ]}
                  pagination={false}
                />
              </Col>
              {/* 排序/時間 */}
              <Col span={24}>
                <Row gutter={[12, 12]} align="middle" justify="start">
                  <Col >
                    <Form.Item name="sort" label={i18n.t('sort')}
                                rules={[...verify({ point: 0 }), { max: 99, type: 'number' }]}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                className="w-12">
                      <InputNumber className="w-12"
                                    placeholder={`${i18n.t('inputData')}`}
                                    disabled={!isEditDone }
                      />
                    </Form.Item>
                  </Col>
                  <Col >
                    <Form.Item name="StartDate" label={i18n.t('promotionStartTime')}
                                rules={[{ required: true, message: `${i18n.t('required')}` }]}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                className="w-12">
                      <DatePicker
                        showTime
                        disabledDate={disabledStartDate}
                        disabledTime={disabledStartTime}
                        onChange={(value) => setStartDate(value)}
                        disabled={!isEditDone }
                      />
                    </Form.Item>
                  </Col>
                  <Col >
                    <Form.Item className="w-12" name="EndDate" colon={false}
                                label={
                                  <Row gutter={6} align="middle">
                                    <Col>{i18n.t('promotionEndTime')}</Col>
                                    <Col>
                                      <Tooltip placement='top'
                                              title={i18n.t('IfTheEndTimeIsNotSetItWillBeRegardedAsAPermanentActivity')}>
                                        <InfoCircleFilled />
                                      </Tooltip>
                                    </Col>
                                  </Row>
                                }>
                      <DatePicker
                        showTime
                        allowClear
                        disabled={!isEditDone || !startDate}
                        disabledDate={disabledEndDate}
                        disabledTime={disabledEndTime}
                        placeholder={`${!endDate && i18n.t('permanent')}`}
                        onChange={(value) => setEndDate(value)}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              {/* 上傳封面圖片 */}
              <Col span={24}>
                <Row>
                  <Col span={24}>
                    <Form.Item name="Photo" label={i18n.t('image')} required
                                rules={[{ required: !imageData[0], message: `${i18n.t('uploadImage')}` }]}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}>
                      <UploadImage
                        name="Photo"
                        url={'/upload/images/public/bonus'}
                        imageData={imageData}
                        setImageData={setImageData}
                        form={form}
                        w={1920}
                        h={560}
                        disabled={!isEditDone }
                      />
                    </Form.Item>
                    <div className="color-03 size-12">
                      {i18n.t('onlyJpgOrPngFiles')}
                      <span className="require">{i18n.t('imageMobileVersionWidth1920pxHeight560px')}</span>
                      {i18n.t('allowedAndSizeNotExceed500kb')}
                    </div>
                  </Col>
                </Row>
              </Col>
              {/* 內文編輯器 */}
              <Col span={24}>
                <Form.Item name="internalContent" label={i18n.t('internalContent')}
                            rules={[{ required: true, message: `${i18n.t('required')}` }]}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}>
                  <CKEditorComp data={editorContent} setData={setEditorContent} form={form} fieldName={'internalContent'} image disabled={!isEditDone }/>
                </Form.Item>
              </Col>
            </Row>
          )}
        </Form>
      </Content>
    </div>
  );
};

export default PageMain;