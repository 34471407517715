import { InfoCircleFilled } from '@ant-design/icons';
import { Button, Col, Descriptions, Form, Input, Popover, Row, Select, Table, Tooltip, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useForm } from 'antd/lib/form/Form';
import { LayoutNav, LayoutTabMember } from 'components/layout.component';
import { RESPONSE_CODE } from 'constants/response';
import { ENABLE, REBATE_PERIOD } from 'enum/state';
import useAccount from 'hooks/account.hook';
import useSite from 'hooks/site.hook';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { $get, $post } from 'services';
import * as common from 'utils/common';
import { convertedToPercentage01 as $g, verify, convertedToPercentage02 as $p2n } from 'utils/common';

// 會員返水

interface RebateType {
  key: number;
  game: string;
  rebate: number;
  name: string;
  agentRebate: number;
}

enum REBATE_SETTINGS {
  default = 1,
  customization = 0,
}

const PageMain: React.FC = () => {
  const navigate = useNavigate();
  const [form] = useForm();
  const { permissions: $p } = useAccount();
  const { data: $s } = useSite();
  const { id, account, agId } = useParams();

  // 代理返水(最大值)
  const { data: agentOpp } = $get({
    url: 'api/agentoperationparams/one',
    params: { AgentId: agId },
    allow: !!agId
  })
  // 取得代理批次設定會員返水(預設值)
  const { data: defaultOpp } = $get({ 
    url: 'api/memberoperationparams/default/info',
    params: { MemberId: id }
  });
  // 會員返水
  const { data: memberOpp, isValidating } = $get({
    url: 'api/memberoperationparams/one',
    params: { MemberId: id }
  });
  const [data, setData] = useState<RebateType[]>([]);
  useEffect(() => {
    if (memberOpp && agentOpp) {
      setData([
        {
          key: 1,
          game: i18n.t('EGAMES'),
          name: 'EGamesCommissionRate',
          rebate: memberOpp.Data.EGamesCommissionRate,
          agentRebate: agentOpp.Data.EGamesCommissionRate
        },
        {
          key: 2,
          game: i18n.t('LIVE'),
          name: 'LiveCommissionRate',
          rebate: memberOpp.Data.LiveCommissionRate,
          agentRebate: agentOpp.Data.LiveCommissionRate
        },
        {
          key: 3,
          game: i18n.t('SPORTS'),
          name: 'SportsCommissionRate',
          rebate: memberOpp.Data.SportsCommissionRate,
          agentRebate: agentOpp.Data.SportsCommissionRate
        },
        {
          key: 4,
          game: i18n.t('ESPORTS'),
          name: 'ESportsCommissionRate',
          rebate: memberOpp.Data.ESportsCommissionRate,
          agentRebate: agentOpp.Data.ESportsCommissionRate
        },
        {
          key: 5,
          game: i18n.t('POKER'),
          name: 'PokerCommissionRate',
          rebate: memberOpp.Data.PokerCommissionRate,
          agentRebate: agentOpp.Data.PokerCommissionRate
        },
        {
          key: 6,
          game: i18n.t('FISHING'),
          name: 'FishingCommissionRate',
          rebate: memberOpp.Data.FishingCommissionRate,
          agentRebate: agentOpp.Data.FishingCommissionRate
        },
        {
          key: 7,
          game: i18n.t('COCKFIGHT'),
          name: 'CockfightCommissionRate',
          rebate: memberOpp.Data.CockfightCommissionRate,
          agentRebate: agentOpp.Data.CockfightCommissionRate
        },
        {
          key: 8,
          game: i18n.t('LOTTERY'),
          name: 'LotteryCommissionRate',
          rebate: memberOpp.Data.LotteryCommissionRate,
          agentRebate: agentOpp.Data.LotteryCommissionRate
        },
        {
          key: 9,
          game: i18n.t('SLOT'),
          name: 'SlotCommissionRate',
          rebate: memberOpp.Data.SlotCommissionRate,
          agentRebate: agentOpp.Data.SlotCommissionRate
        }
      ])
    }
    if (memberOpp) {
      form.setFieldsValue({
        Status: memberOpp.Data.Status,
        IsDefault: memberOpp.Data.IsDefault,
        CommissionSettleCycle: memberOpp.Data.CommissionSettleCycle,
        EGamesCommissionRate: $g(memberOpp.Data.EGamesCommissionRate),
        LiveCommissionRate: $g(memberOpp.Data.LiveCommissionRate),
        SportsCommissionRate: $g(memberOpp.Data.SportsCommissionRate),
        ESportsCommissionRate: $g(memberOpp.Data.ESportsCommissionRate),
        PokerCommissionRate: $g(memberOpp.Data.PokerCommissionRate),
        FishingCommissionRate: $g(memberOpp.Data.FishingCommissionRate),
        CockfightCommissionRate: $g(memberOpp.Data.CockfightCommissionRate),
        LotteryCommissionRate: $g(memberOpp.Data.LotteryCommissionRate),
        SlotCommissionRate: $g(memberOpp.Data.SlotCommissionRate)
      })
      setStatusDisabled(memberOpp.Data.Status === ENABLE.disabled);
    }

  }, [memberOpp, agentOpp])

  const handleSetAllRebate = (value: string) => {
    if (common.decimalNumber(value)) {
      form.setFieldsValue({
        EGamesCommissionRate: value,
        LiveCommissionRate: value,
        SportsCommissionRate: value,
        ESportsCommissionRate: value,
        PokerCommissionRate: value,
        FishingCommissionRate: value,
        CockfightCommissionRate: value,
        LotteryCommissionRate: value,
        SlotCommissionRate: value
      })
    }
  }

  const [isAllDisabled, setIsAllDisabled] = useState(false);
  const [statusDisabled, setStatusDisabled] = useState(false);
  const [isOpenPopover, setIsOpenPopover] = useState(false);
  const save = async (formData: any) => {
    const IsDefault = (
      parseFloat(formData.EGamesCommissionRate).toFixed(2) === $g(defaultOpp.Data.EGamesCommissionRate) &&
      parseFloat(formData.LiveCommissionRate).toFixed(2) === $g(defaultOpp.Data.LiveCommissionRate) &&
      parseFloat(formData.SportsCommissionRate).toFixed(2) === $g(defaultOpp.Data.SportsCommissionRate) &&
      parseFloat(formData.ESportsCommissionRate).toFixed(2) === $g(defaultOpp.Data.ESportsCommissionRate) &&
      parseFloat(formData.PokerCommissionRate).toFixed(2) === $g(defaultOpp.Data.PokerCommissionRate) &&
      parseFloat(formData.FishingCommissionRate).toFixed(2) === $g(defaultOpp.Data.FishingCommissionRate) &&
      parseFloat(formData.CockfightCommissionRate).toFixed(2) === $g(defaultOpp.Data.CockfightCommissionRate) &&
      parseFloat(formData.LotteryCommissionRate).toFixed(2) === $g(defaultOpp.Data.LotteryCommissionRate) &&
      parseFloat(formData.SlotCommissionRate).toFixed(2) === $g(defaultOpp.Data.SlotCommissionRate)
    ) ? REBATE_SETTINGS.default : REBATE_SETTINGS.customization

    form.setFieldValue('IsDefault', IsDefault);

    const send = $s.CommissionModule === '2'
     ? {
      MemberId: id,
      Status: formData.Status,
    }
    : {
      MemberId: id,
      Status: formData.Status,
      EGamesCommissionRate: parseFloat($p2n(formData.EGamesCommissionRate)),
      SlotCommissionRate: parseFloat($p2n(formData.SlotCommissionRate)),
      LiveCommissionRate: parseFloat($p2n(formData.LiveCommissionRate)),
      SportsCommissionRate: parseFloat($p2n(formData.SportsCommissionRate)),
      ESportsCommissionRate: parseFloat($p2n(formData.ESportsCommissionRate)),
      PokerCommissionRate: parseFloat($p2n(formData.PokerCommissionRate)),
      FishingCommissionRate: parseFloat($p2n(formData.FishingCommissionRate)),
      CockfightCommissionRate: parseFloat($p2n(formData.CockfightCommissionRate)),
      LotteryCommissionRate: parseFloat($p2n(formData.LotteryCommissionRate)),
    }

    $post({
      url: 'api/memberoperationparams/update',
      send,
      success: () => {
        message.success(i18n.t('operationSuccess'));
        setIsAllDisabled(true);
        setIsOpenPopover(false);
      },
      resCode: RESPONSE_CODE
    })
  }

  const update2Default = (val: any) => {
    if (val === REBATE_SETTINGS.default && defaultOpp) {
      form.setFieldsValue({
        EGamesCommissionRate: $g(defaultOpp.Data.EGamesCommissionRate),
        LiveCommissionRate: $g(defaultOpp.Data.LiveCommissionRate),
        SportsCommissionRate: $g(defaultOpp.Data.SportsCommissionRate),
        ESportsCommissionRate: $g(defaultOpp.Data.ESportsCommissionRate),
        PokerCommissionRate: $g(defaultOpp.Data.PokerCommissionRate),
        FishingCommissionRate: $g(defaultOpp.Data.FishingCommissionRate),
        CockfightCommissionRate: $g(defaultOpp.Data.CockfightCommissionRate),
        LotteryCommissionRate: $g(defaultOpp.Data.LotteryCommissionRate),
        SlotCommissionRate: $g(defaultOpp.Data.SlotCommissionRate)
      })
    }
  }

  const disable = $s.CommissionModule === '2';

  return (
    <div id="container">
      <LayoutNav id={id || 0} account={account || ''} agId={agId}/>
      <Content className="pr-2 pl-2 pb-2">
        <LayoutTabMember activeKey="5" id={id || 0} account={account} agId={agId}/>
        <Form form={form} onFinish={save}>
          <Row gutter={[12, 12]}>
            {/* 取消確認 */}
            <Col span={24} className="flex-end">
              {
                (isAllDisabled)
                  ? <Button onClick={() => navigate('/member')}>{i18n.t('close')}</Button>
                  : <>
                    <Button onClick={() => navigate('/member')} className="mr-1">{i18n.t('cancel')}</Button>
                    {
                    $p('10602') &&
                    <Button htmlType="submit" type="primary">{i18n.t('confirm')}</Button>
                    }
                  </>
              }
            </Col>
            {/* 返水週期 */}
            <Col span={24}>
              <Descriptions layout="vertical" column={5} bordered size="small">
                {/* 返水週期 */}
                <Descriptions.Item style={{ width: '20%' }} label={
                  <Row align="middle" gutter={[5, 0]}>
                    <Col>
                      <><span className="require">*</span>{i18n.t('rebatePeriod')}</>
                    </Col>
                    <Col>
                      <Tooltip placement="top" className="size-12" title={i18n.t('sameAsUpperAgent')}>
                        <InfoCircleFilled className="center" />
                      </Tooltip>
                    </Col>
                  </Row>
                }>
                  <Form.Item name="CommissionSettleCycle">
                    <Select
                      style={{ width: '100%' }}
                      disabled
                      options={common.enumToOptions(REBATE_PERIOD)}
                    />
                  </Form.Item>
                </Descriptions.Item>
                {/* 狀態 */}
                <Descriptions.Item style={{ width: '20%' }} label={<><span className="require">*</span>{i18n.t('status')}</>}>
                  <Form.Item name="Status">
                    <Select
                      style={{ width: '100%' }}
                      placeholder={`${i18n.t('pleaseSelect')}`}
                      disabled={isAllDisabled || !$p('10602')}
                      options={common.enumToOptions(ENABLE)}
                      onChange={val => setStatusDisabled(val === ENABLE.disabled)}
                    />
                  </Form.Item>
                </Descriptions.Item>
                {/* 返水設定 */}
                {!disable && <Descriptions.Item style={{ width: '20%' }} label={<><span className="require">*</span>{i18n.t('rebateSettings')}</>}>
                  <Form.Item name="IsDefault">
                    <Select
                      style={{ width: '100%' }}
                      placeholder={`${i18n.t('pleaseSelect')}`}
                      disabled={isAllDisabled || !$p('10602')}
                      options={common.enumToOptions(REBATE_SETTINGS)}
                      onChange={update2Default}
                    />
                  </Form.Item>
                </Descriptions.Item>}
                {/* 更新人員 */}
                <Descriptions.Item style={{ width: '20%' }} label={<><span className="require">*</span>{i18n.t('updateStaff')}</>}>
                  {memberOpp?.Data.Operator || '-'}
                </Descriptions.Item>
                {/* 更新時間 */}
                <Descriptions.Item  style={{ width: '20%' }}label={<><span className="require">*</span>{i18n.t('updateTime')}</>}>
                  {memberOpp?.Data.UpdateDate}
                </Descriptions.Item>
              </Descriptions>
            </Col>
            {/* 批次設定 */}
            {!disable && <Col span={24} className="text-end">
              <Popover placement="bottomLeft" trigger="click" open={isOpenPopover} content={
                <Row gutter={[12, 12]}>
                  <Col span={24} className="w-6">
                    <Form.Item name="batchSetting">
                      <Input placeholder={`${i18n.t('inputData')}`}
                        onChange={(e) => handleSetAllRebate(e.target.value)} onPressEnter={() => setIsOpenPopover(false)} />
                    </Form.Item>
                  </Col>
                  <Col span={24} className="center">
                    <Button size="small" onClick={() => setIsOpenPopover(false)}>
                      {i18n.t('close')}
                    </Button>
                  </Col>
                </Row>
              }>
                <Button type="primary" disabled={isAllDisabled || !$p('10602')}
                  onClick={() => setIsOpenPopover(!isOpenPopover)}>
                  {i18n.t('batchSetting')}
                </Button>
              </Popover>
            </Col>}
            {/* 遊戲大類 */}
            <Col span={24}>
              <Row>
                <Col span={12}>
                  <Table
                    loading={isValidating}
                    bordered
                    size="small"
                    className={disable ? 'pt-1' : ''}
                    dataSource={data?.filter((_, i) => i % 2 === 0)}
                    columns={[
                      {
                        dataIndex: 'game',
                        title: <><span className="require">*</span>{i18n.t('gameCategory')}</>,
                        className: 'color-03',
                        width: '25%',
                        render: (val, record) => disable ? `${val} %` : `${val} % (max：${$g(record.agentRebate)})`
                      },
                      {
                        title: <> <span className="require">*</span>{i18n.t('rebateRate')}%</>,
                        width: '25%',
                        render: (_, record) => (
                          <Form.Item name={record.name} rules={verify({ max: disable ? null : $g(record.agentRebate) })}>
                            <Input name={record.name} className="size-12" 
                              disabled={disable || isAllDisabled || statusDisabled || !$p('10602')} 
                              defaultValue={common.convertedToPercentage02(record.rebate)} />
                          </Form.Item>
                        )
                      },
                    ]}
                    pagination={false}
                  />
                </Col >
                <Col span={12}>
                  <Table
                    loading={isValidating}
                    bordered
                    size="small"
                    className={disable ? 'pt-1' : ''}
                    dataSource={data && data.filter((_, i) => i % 2 !== 0)}
                    columns={[
                      {
                        dataIndex: 'game',
                        title: <> <span className="require">*</span>{i18n.t('gameCategory')}</>,
                        className: 'color-03',
                        width: '25%',
                        render: (val, record) => disable ? `${val} %` : `${val} % (max：${$g(record.agentRebate)})`
                      },
                      {
                        title: <> <span className="require">*</span>{i18n.t('rebateRate')}%</>,
                        width: '25%',
                        render: (_, record) => (
                          <Form.Item name={record.name} rules={verify({ max: disable ? null : $g(record.agentRebate) })}>
                            <Input name={record.name} className="size-12" 
                              disabled={disable || isAllDisabled || statusDisabled || !$p('10602')} 
                              defaultValue={common.convertedToPercentage02(record.rebate)} />
                          </Form.Item>
                        )
                      },
                    ]}
                    pagination={false}
                  />
                </Col>
              </Row >
            </Col >
          </Row >
        </Form >
      </Content >
    </div >
  );
};

export default PageMain;