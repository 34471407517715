import i18n from "i18n";

// NOTE: 預設是RESPONSE_CODE如果有繼承同KEY的句子會覆蓋
// NOTE: 合併重複的語句
export const RESPONSE_CODE = {
  unauthorized: i18n.t('notLogin'),
  UNAUTHORIZATION: i18n.t('notLogin'),
  INCORRECT_PARAMS: i18n.t('incorrectRequest'),
  INCORRECT_ACCOUNT: i18n.t('INCORRECT_ACCOUNT'),
  INCORRECT_ACCOUNT_FORMAT: i18n.t('INCORRECT_ACCOUNT_FORMAT'),
  INCORRECT_ACCOUNT_OR_PASSWORD: `${i18n.t('incorrectAccountOrPassword')}`,
  INCORRECT_PASSWORD: `${i18n.t('INCORRECT_PASSWORD')}`,
  INCORRECT_PASSWORD_FORMAT: `${i18n.t('incorrectPasswordFormat')}`,
  INCORRECT_REPEAT_PASSWORD: i18n.t('INCORRECT_REPEAT_PASSWORD'),
  INCORRECT_ID: i18n.t('incorrectMemberAccount'),
  INCORRECT_NAME: i18n.t('INCORRECT_ACCOUNT'),
  INCORRECT_STATUS: i18n.t('incorrectStatus'),
  INCORRECT_DATE_FORMAT: i18n.t('incorrectDate'),
  INCORRECT_SMS_CODE: i18n.t('verificationCodeError'),
  LEVEL_ALERADY_USE: i18n.t('cannotSetDuplicateLevels'),
  NAME_ALEARDY_USE: i18n.t('duplicateName'),
  DUPLICATE_NAME: i18n.t('duplicateName'),
  DUPLICATE_ACCOUNT: i18n.t('DUPLICATE_ACCOUNT'),
  DUPLICATE_IDNumber: i18n.t('duplicateIdNumber'),
  DUPLICATE_Phone: i18n.t('duplicatePhone'),
  DUPLICATE_Email: i18n.t('duplicateEmail'),
  MEMBER_HAS_BEEN_KYC: i18n.t('memberHasBeenVerify'),
  MEMBER_HAS_NOT_BEEN_VERIFIED: i18n.t('memberHadNotVerify'),
  NOT_FIND_MEMBER: i18n.t('NOT_FIND_MEMBER'),
  NOT_FIND_AGENT: i18n.t('agentNotFound'),
  NOT_FIND_ACCOUNT: i18n.t('notFindAccount'),
  NOT_FOUND_MEMBER: i18n.t('noSuchMemberFound'),
  MEMBER_NOT_FOUND: i18n.t('noSuchMemberFound'),
  ITEM_NOT_FOUND: i18n.t('notFoundData'),
  AGENT_NOT_FOUND: i18n.t('noSuchAgentFound'),
  AGENTS_STATUS_CAUSE_CAN_NOT_DEPOSIT: i18n.t('AGENTS_STATUS_CAUSE_CAN_NOT_DEPOSIT'),
  AGENT_INSUFFICIENT_BALANCE: i18n.t('insufficientBalance'),
  INSUFFICIENT_BALANCE: i18n.t('insufficientBalance'),
  APPLY_AMOUNT_OVER_LIMIT: i18n.t('amountLimitExceeded'),
  PHONE_EXISTED: i18n.t('duplicatePhone'),
  PERMISSION_DENIED: i18n.t('permissionDenied'),
  ACCOUNT_BLOCKED: i18n.t('accountDisabled'),
  WITHDRAW_METHOD_PRE_APPROVED_BEFOURE_VERIFICATION: i18n.t('pendingWithdrawalMethodExists'),
  WITHDRAW_METHOD_BANK_ACCOUNT_EXISTED: i18n.t('accountNumberDuplicate'),
  WITHDRAW_METHOD_CRYPTO_ADDRESS_EXISTED: i18n.t('walletAddressDuplicate'),
  PAYMENT_MERCHANT_GOT_ERROR: i18n.t('PAYMENT_MERCHANT_GOT_ERROR'),
  PAYMENT_MERCHANT_MAINTAIN: i18n.t('PAYMENT_MERCHANT_MAINTAIN'),
  MORE_THAN_FILE_SIZE_MAX_LIMIT: i18n.t('imageExceedsSizeLimit'),
  WALLET_BALANCE_MAXIMUM_LIMIT: i18n.t('agentBalanceLimitReached'), // 站長調帳給代理 代理餘額已達上限
  BONUS_NOT_AVAILABLE: i18n.t('activityExpired'),
  RULE_FAIL: i18n.t('incorrectReferralBonusRule'),
  imageSizeWrong: i18n.t('imageSizeWrong'),
  MENBER_NOT_FOUND: i18n.t('incorrectMemberAccount'),
  OPERAT_FORBIDDEN_CAUSE_CHANGE_MEMBER: i18n.t('changeLineProgress'),
  OPERAT_FORBIDDEN_CAUSE_BATCH_REJECT: i18n.t('batchRejectInProgress'),
  OPERAT_FORBIDDEN_CAUSE_ALL_PASS: i18n.t('distributeAllInProgress'),
  OPERAT_FORBIDDEN_CAUSE_RECALCULATE: i18n.t('rebateRecalculationProgress'),
  OPERAT_FORBIDDEN_CAUSE_RECALCULATE_UNREAD: i18n.t('rebateRecountResultUnread'),
  INCORRECT_EMAIL_FORMAT: i18n.t('INCORRECT_EMAIL_FORMAT')
};
export const RESPONSE_CODE_PERMISSION = {
  INCORRECT_ACCOUNT_FORMAT: i18n.t('INCORRECT_ACCOUNT_FORMAT'),
  INCORRECT_PARAMS: i18n.t('incorrectRequest'),
  INCORRECT_NAME: i18n.t('INCORRECT_ACCOUNT'),
  DUPLICATE_ACCOUNT: i18n.t('DUPLICATE_ACCOUNT'),
  DUPLICATE_NAME: i18n.t('duplicateName'),
}
// NOTE: ONE NOTE 上面也是會漏 目前沒有統一文件
export const RESPONSE_CODE_AGENT = {
  INCORRECT_PARAMS: i18n.t('incorrectRequest'),
  INCORRECT_ACCOUNT_FORMAT: i18n.t('INCORRECT_ACCOUNT_FORMAT'),
  INCORRECT_REPEAT_PASSWORD: i18n.t('INCORRECT_REPEAT_PASSWORD'),
  DUPLICATE_ACCOUNT: i18n.t('DUPLICATE_ACCOUNT'),
  INCORRECT_CONFIRM_PASSWORD: i18n.t('newPasswordError'),
}
export const RESPONSE_CODE_ADJUST = {
  NOT_FIND_MEMBER: i18n.t('NOT_FIND_MEMBER'),
  INCORRECT_ID: i18n.t('incorrectMemberAccount'),
  INCORRECT_PARAMS: i18n.t('incorrectRequest'),
  TICKET_NOT_EXISTS: i18n.t('notFoundOrder'),
  WALLET_NOT_EXISTS: i18n.t('notFoundWallet'),
  TICKET_STATUS_ILLEGAL: i18n.t('orderStatusChanged'),
  WALLET_BALANCE_MAXIMUM_LIMIT: i18n.t('memberBalanceLimitReached'), // 站長調帳給會員 會員餘額已達上限
}
export const RESPONSE_CODE_MEMBER = {
  SOURCE_ACCOUNT_NOT_FOUND: i18n.t('sourceAccountDoesNotExist'),
  INCORRECT_ID: i18n.t('incorrectMemberAccount'),
  INCORRECT_NAME: i18n.t('incorrectRealName'),
  INCORRECT_PARAMS: i18n.t('incorrectRequest'),
  INCORRECT_STATUS: i18n.t('incorrectStatus'),
  INCORRECT_ACCOUNT: i18n.t('INCORRECT_ACCOUNT'),
  INCORRECT_DATE_FORMAT: i18n.t('incorrectDate'),
  ITEM_NOT_FOUND: i18n.t('notFoundData'),
  NOT_FIND_MEMBER: i18n.t('NOT_FIND_MEMBER'),
  IDNUMBER_EXISTED: i18n.t('duplicateIdNumber'),
  MEMBER_HAS_NOT_BEEN_VERIFIED: i18n.t('memberHadNotVerify'),
  MEMBER_SUSPENDED: i18n.t('memberFrozen'),
  DUPLICATE_ACCOUNT: i18n.t('DUPLICATE_ACCOUNT'),
  INCORRECT_ACCOUNT_FORMAT: i18n.t('INCORRECT_ACCOUNT_FORMAT'),
  DUPLICATE_IDNumber: i18n.t('duplicateIdNumber'),
  DUPLICATE_Phone: i18n.t('duplicatePhone'),
  DUPLICATE_Email: i18n.t('duplicateEmail'),
  MEMBER_HAS_BEEN_KYC: i18n.t('memberHasBeenVerify'),
  MEMBER_ALREADY_SEND_KYC: i18n.t('memberHasBeenVerify'),
  PHONE_EXISTED: i18n.t('PHONE_EXISTED'),
}
export const RESPONSE_CODE_PROMOTION = {
  INCORRECT_NAME: '',
  DUPLICATE_ORDERING: i18n.t('levelNameCannotBeDuplicated'),
  DUPLICATE_NAME: i18n.t('levelNameCannotBeDuplicated'),
  INCORRECT_PARAMS: i18n.t('operationError'),
}
export const RESPONSE_CODE_BONUS = {
  PERIOD_REPEAT: i18n.t('activityCycleOverlapsWithActivityTime'),
  INCORRECT_NAME: i18n.t('incorrectPromotionName'),
  DUPLICATE_NAME: i18n.t('duplicatePromotionName'),
  GAME_ID_ALREADY_USE: i18n.t('GAME_ID_ALREADY_USE'),
  GAME_ID_FORBIDDEN: i18n.t('gameIdForbidden'),
  PERIOD_CHANGE_DATE_FORBIDDEN: i18n.t('cycleChangeTheCurrentTimeCannotFallBetweenTheStartAndEndPeriod'),
  IP_RECEIVE_COUNT_FORBIDDEN: i18n.t('ipReceiveCountForbidden'),
  IP_RECEIVE_COUNT_MIN_LIMIT: i18n.t('ipReceiveCountMinLimit'),
  SOMEONE_HAS_HIT_THE_JACKPOT: i18n.t('cantEditAfterPrizeing'),
}
export const RESPONSE_CODE_BONUSTICKET_CREATE = {
  INCORRECT_PARAMS: i18n.t('incorrectRequest'),
  NOT_FIND_BONUS: i18n.t('notFindBonus'),
  BONUS_NOT_AVAILABLE: i18n.t('bonusNotAvailable'),
  TICKET_STATUS_ILLEGAL: i18n.t('orderOperationFailed'),
}
export const RESPONSE_CODE_GAME = {
  INCORRECT_PARAMS: i18n.t('operationError'),
  INCORRECT_ID: i18n.t('incorrectGame'),
  INCORRECT_NAME: i18n.t('incorrectGameName')
}
export const RESPONSE_CODE_FINANCE = {
  INCORRECT_PARAMS: i18n.t('operationError'),
  INCORRECT_ID: ``,
  TICKET_STATUS_FORBIDDEN: i18n.t('orderStatusChanged')
}
export const RESPONSE_CODE_BANKCARD = {
  ACCOUNT_ALEARDY_USE: i18n.t('duplicateBankCard'),
  INCORRECT_PARAMS: i18n.t('operationError'),
  WITHDRAW_METHOD_PRE_APPROVED_BEFOURE_VERIFICATION: i18n.t('approvedBefoureVerification')
}
export const RESPONSE_CODE_GAME_DATA = {
  INCORRECT_PARAMS: i18n.t('operationError'),
}
export const RESPONSE_CODE_MEMBER_PROVIDER_STATUS = {
  INCORRECT_PARAMS: i18n.t('operationError'),
}
export const RESPONSE_CODE_SITE_SETTING = {
  INCORRECT_PARAMS: i18n.t('operationError'),
  INCORRECT_IP: i18n.t('ipFormatNotValid')
}
export const RESPONSE_CODE_LAYOUT_SETTING = {
  INCORRECT_PARAMS: i18n.t('operationError'),
}
export const RESPONSE_CODE_AGENT_WITHDRAW = {
  TICKET_STATUS_ILLEGAL: i18n.t('orderStatusChanged'),
  ANOTHER_FINANCE_IS_HANDLING: i18n.t('orderCurrentlyLocked'),
  INCORRECT_PARAMS: i18n.t('operationError'),
  WITHDRAW_MERCHANT_EXECUTE_FAILED: i18n.t('withdrawalMerchantExecutionFailed'),
  WITHDRAW_PROCESS_GOT_ERROR: i18n.t('withdrawalMerchantExecutionError'),
  TICKET_NOT_FOUND: i18n.t('withdrawalOrderDoesNotExist'),
  MERCHANT_NOT_FOUND: i18n.t('withdrawalMerchantDoesNotExist'),
  WITHDRAW_METHOD_NOT_FOUND: i18n.t('withdrawalMethodDoesNotExist'),
  PAYMENT_MERCHANT_GOT_ERROR: i18n.t('withdrawalMethodDoesNotExist'),
}
export const RESPONSE_CODE_REBATE = {
  INCORRECT_PARAMS: i18n.t('operationError'),
  COMMISSION_TICKET_NOT_FIND: i18n.t('notFoundData'),
  TICKET_STATUS_ILLEGAL: i18n.t('orderStatusChanged'),
  NOT_FIND_BONUS_TICKET: i18n.t('notFindBonusTicket'),
}
export const RESPONSE_CODE_CREATE_ANNOUNCEMENT = {
  INCORRECT_PARAMS: i18n.t('operationError'),
  INCORRECT_DATE_FORMAT: i18n.t('publicationTimeError')
}
export const RESPONSE_CODE_MAINTENANCE = {
  INCORRECT_PARAMS: i18n.t('operationError'),
  DUPLICATE_TIME_RANGE: i18n.t('gameProviderMaintenanceTimeCannotOverlap')
}
export const RESPONSE_CODE_WITHDRAW_UPDATE = {
  INCORRECT_PARAMS: i18n.t('operationError'),
}
export const RESPONSE_CODE_ADD_DEPOSIT = {
  INCORRECT_PARAMS: i18n.t('operationError'),
  INCORRECT_ID: i18n.t('processingFailed'),
}
export const RESPONSE_CODE_MERCHANT = {
  INCORRECT_PARAMS: i18n.t('operationError'),
}
export const RESPONSE_CODE_IP = {
  BLOCK_IP_NOT_FIND: i18n.t('thisIpIsUnBlocked'),
  INCORRECT_PARAMS: i18n.t('operationError'),
  DUPLICATE_IP: i18n.t('thisIpIsBlocked')
}
export const RESPONSE_CODE_TAG = {
  DUPLICATE_NAME: i18n.t('duplicateLabelName'),
  INCORRECT_PARAMS: i18n.t('operationError'),
  AGENT_NOT_FIND: i18n.t('accountDoesNotExist')
}
export const RESPONSE_CODE_GAME_IP = {
  INCORRECT_PARAMS: i18n.t('operationError'),
}
export const RESPONSE_CODE_FRONT = {
  INCORRECT_PARAMS: i18n.t('operationError'),
}
export const RESPONSE_CODE_SEO = {
  INCORRECT_PARAMS: i18n.t('operationError'),
  TYPE_ALEARDY_USE: i18n.t('duplicatePageSEOSettings')
}
export const RESPONSE_CODE_CHANGELINE = {
  INCORRECT_PARAMS: i18n.t('operationError'),
  INCORRECT_ID: i18n.t(''),
  INCORRECT_ACCOUNT_FORMAT: i18n.t(''),
  DOWNLINE_AGENT_FORBIDDEN: i18n.t('cannotChangeToSubordinateAgency')
}
export const RESPONSE_CODE_LICENSE = {
  INCORRECT_PARAMS: i18n.t('operationError')
}
export const RESPONSE_CODE_CHAT = {
  ACCOUNT_ISONLINE_FALSE: i18n.t('customerServiceNotOnline'),
  INCORRECT_PARAMS: i18n.t('operationError'),
  DUPLICATE_NAME: i18n.t('duplicateCategoryName')
}
export const RESPONSE_CODE_MAIL = {
  NOT_FIND_MEMBER: i18n.t('memberNotFound')
}
export const RESPONSE_CODE_GAME_UPDATE = {
  INCORRECT_ID: i18n.t(''),
  INCORRECT_STATUS: i18n.t('')
}

export const RESPONSE_CODE_MESSAGE_TEMPLATE_STATUS = {
  MAIL_SETTING_NOT_FOUND: i18n.t('instantMessageDoesNotExist'),
}