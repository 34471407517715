import { DatabaseFilled } from '@ant-design/icons';
import { Button, Checkbox, Col, ColorPicker, Divider, Form, Image, Input, InputNumber, message, Modal, Popover, Row, Select, Space, Spin, Table, Tag, Tooltip } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import remarkIcon from 'assets/image/icon-11.svg';
import hintIcon from 'assets/image/icon-12.png';
import { CopyButton, EditableCell, Export, LayoutPagination, LayoutTextArea } from 'components/layout.component';
import { ExcelColumns } from 'constants/excel';
import { REGEX_ACCOUNT, REGEX_PASSWORD } from 'constants/regex';
import { RESPONSE_CODE_MEMBER, RESPONSE_CODE_TAG } from 'constants/response';
import { SETTLE_CYCLE } from 'enum/promotion';
import { ENABLE, MEMBER_FREEZE, MEMBER_FREEZE_REPORT } from 'enum/state';
import useAccount from 'hooks/account.hook';
import useIcon from 'hooks/icon.hook';
import useSite from 'hooks/site.hook';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { $get, $post } from 'services';
import * as common from 'utils/common';
import { convertedToPercentage01 as $g, enumToOptions, timeS2L, toFormatNumber, verify } from "utils/common";
import { TagTableDisplay } from "./account.component";

// 會員列表
export const ViewMemberList = ({ data, mutate, searchParam, isValidating }: {
  data: {
    State: string;
    Data: Member[];
    TotalRecord: number;
  }
  isValidating: any;
  mutate: () => void;
  searchParam: MemberSearchParam;
}) => {
  const icon = useIcon();
  const navigate = useNavigate();

  const { init, permissions: $p } = useAccount();
  const { data: $s } = useSite();
  const [status, setStatus] = useState<number>();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isOpenAddMemberModal, setIsOpenAddMemberModal] = useState(false);
  const [isEditCommentModal, setIsEditCommentModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentId, setCurrentId] = useState(0);
  const [remark, setRemark] = useState('');

  const { data: OnlineMembers } = $get({ url: 'api/report/online/members' });
  // 會員標籤列表
  const { data: TagList } = $get({ url: 'api/tag/member/list' });
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => setSelectedRowKeys(newSelectedRowKeys);

  // 修改審核
  const handleOkStatus = () => {
    handleCancelStatus();
    if (selectedRowKeys.length === 0) return;
    $post({
      url: 'api/member/update/freeze',
      send: {
        Ids: selectedRowKeys,
        freeze: status,
      },
      success: () => {
        message.success(i18n.t('updateSuccess'));
        mutate();
      },
      resCode: RESPONSE_CODE_MEMBER
    })
  };

  const handleCancelStatus = () => {
    setStatus(undefined);
    setSelectedRowKeys([]);
    setIsModalOpen(false);
  };

  const [columns, setColumns] = useState<any>([]);

  const updateColumnShow = (key: string, checked: boolean) => {
    columns.find((item: any) => {
      if (item.dataIndex === key) {
        item.show = checked
        return true
      }
    })
    setColumns([...columns]);
  };

  const waterHover = (record: any, word: any) => <>
    <Tooltip placement="bottom" title={
      <Spin spinning={false}>
        <div>{`${i18n.t('EGAMES')}: ${$g(record.EGamesCommissionRate)}%`}</div>
        <div>{`${i18n.t('LIVE')}: ${$g(record.LiveCommissionRate)}%`}</div>
        <div>{`${i18n.t('SPORTS')}: ${$g(record.SportsCommissionRate)}%`}</div>
        <div>{`${i18n.t('ESPORTS')}: ${$g(record.ESportsCommissionRate)}%`}</div>
        <div>{`${i18n.t('POKER')}: ${$g(record.PokerCommissionRate)}%`}</div>
        <div>{`${i18n.t('FISHING')}: ${$g(record.FishingCommissionRate)}%`}</div>
        <div>{`${i18n.t('COCKFIGHT')}: ${$g(record.CockfightCommissionRate)}%`}</div>
        <div>{`${i18n.t('LOTTERY')}: ${$g(record.LotteryCommissionRate)}%`}</div>
        <div>{`${i18n.t('SLOT')}: ${$g(record.SlotCommissionRate)}%`}</div>
      </Spin>
    }>
      {word}
    </Tooltip>
  </>

  useEffect(() => {
    if (init) {
      setColumns([
        {
          dataIndex: 'Account',
          title: i18n.t('memberAccount'),
          width: 120,
          fixed: 'left',
          show: true,
          render: (val: any, record: any) => (
            <Row justify="start" align="middle">
              <Popover
                title={`${i18n.t('memberInformation')} (${val})`}
                placement="bottomLeft"
                content={() => (
                  <Space style={{ minWidth: 200 }} direction="vertical">
                    <div className='text-start size-12'>
                      <div>{i18n.t('realName')}：{record.Name || '-'}</div>
                      <div>{i18n.t('nickname')}：{record.Nickname || '-'}</div>
                      <div>{i18n.t('identifier')}：{record.NoCode}</div>
                      <div>{i18n.t('birthday')}：{record.Birth && record.Birth !== '0000-00-00' ? record.Birth : '-'}</div>
                      <div>{i18n.t('idNum')}：{record.IDNumber || '-'}</div>
                      <div>{i18n.t('phoneNumber')}：{record.PhoneNumber ? `${record.PhonePrefix}-${record.PhoneNumber}` : '-'}</div>
                      <div>{i18n.t('email')}：{record.Email || '-'}</div>
                      {
                        $p('10201') &&
                        <div className='size-12 text-end'>
                          <Button size="small" type="link" 
                            onClick={() => navigate(`/member/info/${record.Id}/${record.Account}/${record.AGId}`)}>
                            {i18n.t('detail')}
                          </Button>
                        </div>
                      }
                    </div>
                  </Space>
                )}
              >
                <Button size="small" className="size-12" type="link">{val}</Button>
              </Popover>
              <CopyButton text={val} />
            </Row>
          )
        },
        {
          dataIndex: 'RefCode',
          title: i18n.t('promotionCode'),
          width: 100,
          show: true,
        },
        {
          dataIndex: 'UPMemberAccount',
          title: i18n.t('recommendedMembers'),
          width: 120,
          show: true,
          render: (val: any) => val || '-'
        },
        {
          dataIndex: 'AGAccount',
          title: i18n.t('agent'),
          width: 120,
          show: true,
        },
        {
          dataIndex: 'IsFreeze',
          title: `${i18n.t('online')}／${i18n.t('status')}`,
          width: 120,
          show: true,
          render: (val: any, { IsOnline }: any) => (
            <Row align="middle">
              <Col>{icon.StatusTag(IsOnline)}</Col>
              <Col>{icon.MemberStateTag(val ? MEMBER_FREEZE.frozen : MEMBER_FREEZE.normal)}</Col>
            </Row>
          )
        },
        {
          dataIndex: 'Status',
          title: i18n.t('audit'),
          width: 100,
          show: true,
          render: (val: any) => <Row align="middle"><Col>{icon.MemberStateTag(val)}</Col></Row>
        },
        {
          dataIndex: 'MemberLevelName',
          title: i18n.t('memberLevel'),
          width: 100,
          show: true,
          render: (val: any, record: any) => $s.CommissionModule === '2' ? waterHover(record, val) : val
        },
        {
          dataIndex: 'CreateDate',
          title: `${i18n.t('createTime')}／IP`,
          width: 150,
          show: true,
          render: (val: any, { RegisterIP }: any) => (
            <Space direction="vertical">
              <div>{timeS2L(val)}</div>
              <div>{RegisterIP || '-'}</div>
            </Space>
          )
        },
        {
          dataIndex: 'LastLoginDate',
          title: `${i18n.t('lastLoginTime')}／IP`,
          width: 150,
          show: true,
          render: (val: any, { IP }: any) => (
            <Space direction="vertical">
              <div>{timeS2L(val)}</div>
              <div>{IP || '-'}</div>
            </Space>
          )
        },
        {
          dataIndex: 'LastDepositTime',
          title: i18n.t('lastDepositTime'),
          width: 150,
          show: true,
          render: (val: any, { LastDepositGatewayCode }: any) => (
            <Space direction="vertical">
              <div>{timeS2L(val)}</div>
              <div>{i18n.t(LastDepositGatewayCode) || '-'}</div>
            </Space>
          )
        },
        {
          dataIndex: 'CommissionSettleCycle',
          title: i18n.t('rebate'),
          width: 100,
          show: true,
          render: (_: any, record: Agent) => waterHover(record, i18n.t(SETTLE_CYCLE[record.CommissionSettleCycle]))
        },
        {
          dataIndex: 'TagsId',
          title: i18n.t('memberTag'),
          width: 120,
          show: true,
          render: (val: any, record: any) => <TagTableDisplay ids={val} list={TagList} />
        },
        {
          dataIndex: 'RemarkFromAccount',
          title: i18n.t('remark'),
          width: 150,
          show: true,
          // FIXME: memo抽取
          render: (comment: any, { Id, }: any) => (
            <Space>
              <Space direction="vertical">
                {
                  comment &&
                    comment.length > 20
                    ? <Tooltip placement="top" title={comment}>
                      <span>
                        {
                          comment.split('').map((text: string, i: number) => {
                            let outOf20 = '';
                            if (i < 20) {
                              outOf20 += text
                            }
                            return outOf20;
                          })
                        }
                        ...
                      </span>
                    </Tooltip>
                    : <span>{comment || '-'}</span>
                }
                {
                  $p('10204') &&
                  <Button
                    className="center"
                    size="small"
                    type="link"
                    onClick={() => {
                      setIsEditCommentModal(true);
                      setCurrentId(Id);
                      setRemark(comment);
                    }}
                  >
                    <Image className="center" src={remarkIcon} preview={false} />
                  </Button>
                }
              </Space>
            </Space>
          )
        },
        {
          title: i18n.t('operation'),
          width: 150,
          fixed: 'right',
          show: true,
          render: (val: any, { Id, Account, AGId }: any) => (
            <>
              {
                ($p('10201') || $p('10301') || $p('10401') || $p('10501') || $p('10601')) &&
                <Button className="size-12" type="link" onClick={() => {
                  if ($p('10201')) {
                    return navigate(`/member/info/${Id}/${Account}/${AGId}`)
                  } else if ($p('10301')) {
                    return navigate(`/member/provider/${Id}/${Account}/${AGId}`)
                  } else if ($p('10401')) {
                    return navigate(`/member/contact/${Id}/${Account}/${AGId}`)
                  } else if ($p('10501')) {
                    return navigate(`/member/bank/${Id}/${Account}/${AGId}`)
                  } else if ($p('10601')) {
                    return navigate(`/member/rebate/${Id}/${Account}/${AGId}`)
                  }
                }}>
                  {
                    // 至少有一編輯行為就顯示編輯
                    (
                      $p('10202') || $p('10204') || $p('10205') || $p('10206') || 
                      $p('10302') || $p('10303') || 
                      $p('10402') || $p('10403') || $p('10404') || 
                      $p('10503') || $p('10504') || $p('10505') || 
                      $p('10602')
                    ) 
                    ? i18n.t('edit') : i18n.t('detail')
                  }
                </Button>
              }
              {
                $p('10701') &&
                <Button className="size-12" type="link" onClick={() => navigate(`/member/account/${Account}`)}>
                  {i18n.t('accountRecords')}
                </Button>
              }
            </>
          )
        },
      ].map((item, i) => ({ ...item, key: i }))
        .filter(item => !($s.CommissionModule === '2' && item.dataIndex === 'CommissionSettleCycle')))
    }
  }, [init, TagList]);

  return (
    <>
      <Col span={24}>
        <Row align="middle" justify="space-between">
          {/* 左邊 */}
          <Col>
            <Row gutter={[12, 12]}>
              {
                $p('10105') &&
                <Col>
                  <Select
                    className="w-6"
                    placeholder={i18n.t('status')}
                    value={status}
                    disabled={selectedRowKeys.length === 0}
                    options={enumToOptions(MEMBER_FREEZE_REPORT)}
                    onChange={(status: number) => {
                      setStatus(status)
                      setIsModalOpen(true);
                    }}
                  />
                </Col>
              }
              {
                $p('10102') &&
                <Col>
                  <Button type="primary" onClick={() => setIsOpenAddMemberModal(true)}>{i18n.t('add')}</Button>
                </Col>
              }
              {
                $p('10104') &&
                <Col>
                  <Export url={'/member/export'} param={{
                    ...searchParam, PageSize: data && data.TotalRecord, PageIndex: 1
                  }} sheetName={i18n.t('memberList')} columns={ExcelColumns.MemberList} otherData={TagList} />
                </Col>
              }
            </Row>
          </Col>
          {/* 右邊 */}
          <Col>
            <Row align="middle" gutter={[12, 12]}>
              <Col>
                <div className="color-03">{i18n.t('onlineMembers')}：{
                  toFormatNumber(OnlineMembers?.Data.Member, 0) || 0
                }</div>
              </Col>
              <Col>
                <Popover
                  placement="bottomRight"
                  trigger="click"
                  content={
                    <div className="w-10">
                      {columns?.filter((item: any) => item.dataIndex !== 'Account')
                        .map((item: any) =>
                          <div key={item.key}>
                            <Checkbox
                              checked={item.show}
                              onChange={e => updateColumnShow(item.dataIndex, e.target.checked)}
                            >
                              {item.title}
                            </Checkbox>
                          </div>
                        )}
                    </div>
                  }
                >
                  <Button><DatabaseFilled /></Button>
                </Popover>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Table
          loading={isValidating}
          size="small"
          // 多選
          rowSelection={($p('10105') &&
          {
            selectedRowKeys,
            onChange: onSelectChange,
          }) as any
          }
          scroll={{ x: 1500 }}
          dataSource={data?.Data?.map((item: Member) => ({ ...item, key: item.Id }))}
          columns={columns.filter((item: any) => item.show) as any}
          pagination={false}
        />
        <PopupMemberCommentModal isOpen={isEditCommentModal} close={() => setIsEditCommentModal(false)} id={currentId} mutate={mutate} remark={remark} />
        <PopupAddMemberModal isOpen={isOpenAddMemberModal} close={() => setIsOpenAddMemberModal(false)} mutate={mutate} />
        {/* 有的 */}
        <PopupMemberBranchStatue isOpen={isModalOpen} close={() => handleCancelStatus()} onOk={() => handleOkStatus()} />
      </Col>
    </>
  )
}

// 批次更改狀態
export const PopupMemberBranchStatue = ({ isOpen, close, onOk }: {
  isOpen: boolean;
  close: () => void;
  onOk: () => void;
}) => {
  return (
    <Modal
      title={
        <Row gutter={15} align="middle">
          <Image className="center" src={hintIcon} width={16} height={16} preview={false} />
          <Col className="size-16">{i18n.t('prompt')}</Col>
        </Row>
      }
      open={isOpen}
      onOk={onOk}
      onCancel={close}
      okText={i18n.t('confirm')}
      cancelText={i18n.t('cancel')}
      centered>
      <p>{i18n.t('confirmUpdateBatch')}</p>
    </Modal>
  )
}

// 新增備註
export const PopupMemberCommentModal = ({ isOpen, close, id, mutate, remark }: any) => {

  const [form] = Form.useForm();

  useEffect(() => {
    if (isOpen) {
      form.setFieldsValue({
        remark,
      })
    }
  }, [isOpen]);

  const handleSubmit = (formData: any) => {
    $post({
      url: 'api/memo/member/create',
      send: {
        Id: id,
        Noted: formData.remark
      },
      success: () => {
        handleCancel();
        mutate();
        message.success(i18n.t('addSuccess'));
      }
    })
  }

  const handleCancel = () => {
    close();
    form.resetFields();
  };

  return (
    <Modal
      title={i18n.t('remark')}
      open={isOpen}
      onCancel={handleCancel}
      centered
      width={450}
      footer={false}
      forceRender
    >
      <Row>
        <Col span={24}>
          <Form layout="vertical" form={form} onFinish={handleSubmit}>
            <LayoutTextArea formName="remark" formRules={[{
              required: true, message: `${i18n.t("remarksUpTo100Characters")}`,
              type: 'string', max: 100
            }]} autoSize={{ minRows: 4, maxRows: 4 }} maxLength={100} />
          </Form>
        </Col>
        <Col span={24}>
          <Row justify="center" gutter={[12, 12]}>
            <Col>
              <Button className="mt-1" key="cancel" onClick={handleCancel}>{i18n.t('cancel')}</Button>
            </Col>
            <Col>
              <Button className="mt-1" key="confirm" type="primary" onClick={form.submit}>{i18n.t('confirm')}</Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  )
}

// 會員登入記錄
export const PopupMemberLoginHistory = ({ isOpen, close, id, account }: any) => {
  const { data: loginHistory, isValidating } = $get({
    url: 'api/member/login/history',
    params: { MemberId: id },
    allow: !!id
  });

  return (
    <Modal
      title={`${account} ${i18n.t('loginTime')}`}
      open={isOpen}
      onCancel={close}
      width={600}
      centered
      footer={
        <Row justify="center">
          <Button type="primary" key="cancel" onClick={close}>{i18n.t('close')}</Button>
        </Row>
      }
    >
      <Spin spinning={isValidating}>
        <Table
          size="small"
          bordered
          scroll={{ y: 400 }}
          dataSource={
            loginHistory?.Data?.map((item: LoginHistory) => ({ ...item, key: item.Id }))
          }
          columns={[
            {
              title: i18n.t('loginTime'),
              dataIndex: 'CreateDate',
              width: 140,
              render: (val) => timeS2L(val)
            },
            {
              title: i18n.t('IP'),
              dataIndex: 'IP',
              width: 140
            },
            {
              title: i18n.t('IP Geo Address'),
              dataIndex: 'IPLocation',
              width: 140
            },
          ]}
          pagination={false}
        />
      </Spin>
    </Modal>
  )
}
// NOTE: 登入記錄可以合併
// 代理登入記錄
export const PopupIPHistory = ({ isOpen, close, AgentId }: any) => {
  const { data: IPHistory, isValidating } = $get({
    url: 'api/agent/login/history',
    params: { AgentId },
    allow: !!AgentId
  })
  return (
    <Modal
      title={`${i18n.t('loginRecords')}`}
      open={isOpen}
      onCancel={close}
      width={600}
      centered
      footer={
        <Row justify="center">
          <Button type="primary" key="cancel" onClick={close}>{i18n.t('close')}</Button>
        </Row>
      }
    >
      <Spin spinning={isValidating}>
        <Table
          size="small"
          bordered
          scroll={{ y: 400 }}
          dataSource={
            IPHistory?.Data ? IPHistory.Data.map((item: AgentLoginIPHistory) => (
                {
                  key: item.Id,
                  date: item.CreateDate,
                  ip: item.IP
                }
              )) : []
          }
          columns={[
            {
              title: i18n.t('loginTime'),
              dataIndex: 'date',
              width: 140,
              render: (val) => timeS2L(val)
            },
            {
              title: i18n.t('IP'),
              dataIndex: 'ip',
              width: 140
            },
          ]}
          pagination={false}
        />
      </Spin>
    </Modal>
  )
}

// 新增會員
export const PopupAddMemberModal = ({ isOpen, close, mutate }: any) => {
  const [form] = Form.useForm();
  const { data: allAgentList, isValidating } = $get({ url: 'api/agent/all/list' });

  const handleSubmit = (formData: any) => {
    if (!REGEX_ACCOUNT.test(formData.accountAdd) || typeof formData.accountAdd[0] !== 'string') {
      message.error(i18n.t('INCORRECT_ACCOUNT_FORMAT'));
      return;
    }
    if (!REGEX_PASSWORD.test(formData.password)) {
      message.error(i18n.t('incorrectPasswordFormat'));
      return;
    }
    if (formData.nickname?.length !== 0 && (formData.nickname?.length < 4 || formData.nickname?.length > 10)) {
      message.error(i18n.t('nicknameFormatError'));
      return;
    }
    $post({
      url: 'api/member/create',
      send: {
        Account: formData.accountAdd,
        Password: formData.password,
        Type: 0,
        AgentAccount: formData.agent,
        NickName: formData.nickname,
      },
      success: () => {
        message.success(i18n.t('addSuccess'));
        handleCancel();
        mutate();
      },
      resCode: RESPONSE_CODE_MEMBER
    })
  }

  const handleCancel = () => {
    form.resetFields();
    close();
  }

  return (
    <Modal
      title={i18n.t('add')}
      open={isOpen}
      onCancel={handleCancel}
      centered
      width={450}
      footer={false}
      forceRender
    >
      <Form layout="vertical" form={form} onFinish={handleSubmit}>
        <Row className="mb-1" gutter={[12, 12]}>
          <Col span={24}>
            <Form.Item name="accountAdd" label={i18n.t('account')} rules={[
              { required: true, message: `${i18n.t('required')}` },
              { min: 4, message: `4~12${i18n.t('requiresEnglishAndNumbersStartWithEnglish')}` },
              { max: 12, message: `4~12${i18n.t('requiresEnglishAndNumbersStartWithEnglish')}` },
            ]}>
              <Input placeholder={`4~12${i18n.t('requiresEnglishAndNumbersStartWithEnglish')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="password" label={i18n.t('password')} rules={[
              { required: true, message: `${i18n.t('required')}` },
              { min: 4, message: `4~12${i18n.t('requiresEnglishAndNumbersSupportDash')}` },
              { max: 20, message: `4~12${i18n.t('requiresEnglishAndNumbersSupportDash')}` },
            ]}>
              <Input.Password placeholder={`4~20${i18n.t('requiresEnglishAndNumbersSupportDash')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="agent" label={i18n.t('agent')} rules={[
              { required: true, message: `${i18n.t('required')}` }
            ]}>
              <Select
                showSearch
                loading={isValidating}
                className="w-full"
                placeholder={`${i18n.t('pleaseSelect')}`}
                options={
                  allAgentList?.Data?.map((item: { Id: number, Account: string }) => (
                    { value: item.Account, label: item.Account }
                  ))
                }
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="nickname" label={i18n.t('nickname')} rules={[
              { min: 4, message: `4~12${i18n.t('characters')}` },
              { max: 12, message: `4~12${i18n.t('characters')}` },
            ]}>
              <Input placeholder={`4~12${i18n.t('characters')}`} />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="center">
          <Button className="mr-1" onClick={handleCancel}>{i18n.t('cancel')}</Button>
          <Button type="primary" htmlType="submit">{i18n.t('confirm')}</Button>
        </Row>
      </Form>
    </Modal>
  )
}

// 會員備註
export const ViewMemberMemoList = ({ id }: any) => {
  const { info, permissions: $p } = useAccount();
  const [form] = Form.useForm();
  const [isModalOpenDeleteMemo, setIsModalOpenDeleteMemo] = useState(false);
  const [page, setPage] = useState<number[]>([1, 10]);
  const [deleteMemoId, setDeleteMemoId] = useState(0);
  const [editingKey, setEditingKey] = useState(0);
  const [isEditCommentModal, setIsEditCommentModal] = useState(false);

  const { data, mutate, isValidating } = $get({
    url: 'api/memo/member/list',
    params: {
      Id: id,
      PageIndex: page[0],
      PageSize: page[1]
    },
    allow: !!id
  });

  const handleEdit = (record: Memo) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.Id);
  };

  const handleDelete = (id: number) => {
    setDeleteMemoId(id);
    setIsModalOpenDeleteMemo(true);
  }

  const handleOkDelete = () => {
    $post({
      url: 'api/memo/delete',
      send: { Id: deleteMemoId },
      success: () => {
        message.success(i18n.t('deleteSuccess'));
        handleCancelDelete();
        mutate();
      },
      resCode: RESPONSE_CODE_MEMBER
    })
  };

  const handleCancelDelete = () => {
    setDeleteMemoId(0);
    setIsModalOpenDeleteMemo(false);
  }

  const handleSave = (id: number) => {
    const Note = form.getFieldValue('Note');
    $post({
      url: 'api/memo/update',
      send: {
        Id: id,
        Noted: Note,
      },
      success: () => {
        message.success(i18n.t('updateSuccess'));
        setEditingKey(0);
        mutate();
      },
      resCode: RESPONSE_CODE_MEMBER
    })
  };

  return (
    <>
      <Form form={form} component={false}>
        <div className="size-14 font-w-md">{i18n.t('remarkHistory')}</div>
        {$p('10204') &&
          <Row justify="start" gutter={[12, 12]} className="mt-1">
            <Col><Button type="primary" onClick={() => setIsEditCommentModal(true)}>{i18n.t('add')}</Button></Col>
          </Row>
        }
        <Table
          loading={isValidating}
          className="mt-1"
          size="middle"
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          dataSource={data?.Data?.map((item: Memo) => ({ ...item, key: item.Id }))}
          columns={[
            {
              editable: true,
              dataIndex: 'Note',
              title: i18n.t('remark'),
            },
            {
              dataIndex: 'OperatorAccount',
              title: i18n.t('updater'),
              width: 300,
              render: (val: string) => val || 'System'
            },
            {
              dataIndex: 'UpdateDate',
              title: i18n.t('updateTime'),
              width: 300,
              render: (val: string) => timeS2L(val)
            },
            {
              title: i18n.t('operation'),
              width: 160,
              render: (_: any, record: Memo) => {
                return (
                  <>
                    {
                      ($p('10205') && info.Account === record.OperatorAccount || (info.Account === 'System' && record.OperatorAccount === null)) &&
                      <>
                        {record.Id === editingKey ?
                          <>
                            <Button type="link" onClick={() => handleSave(record.Id)}>{i18n.t('confirm')}</Button>
                            <Button type="link" onClick={() => setEditingKey(0)}>{i18n.t('cancel')}</Button>
                            <Divider type="vertical" />
                          </> :
                          <Button type="link" disabled={editingKey !== 0} onClick={() => handleEdit(record)}>{i18n.t('edit')}</Button>
                        }
                      </>
                    }
                    {
                      ($p('10206') && info.Account === record.OperatorAccount || (info.Account === 'System' && record.OperatorAccount === null)) &&
                      <Button className="size-12" type="link" onClick={() => handleDelete(record.Id)}>{i18n.t('delete')}</Button>
                    }
                  </>
                )
              },
            },
          ].map((col: any) => {
            if (!col.editable) {
              return col;
            }
            return {
              ...col,
              onCell: (record: Memo) => ({
                record,
                inputType: 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: record.Id === editingKey,
              }),
            };
          })}
          pagination={false}
        />
        <LayoutPagination total={data?.TotalRecord || 0} setPage={setPage} page={page} />
      </Form>
      <PopupMemberCommentModal isOpen={isEditCommentModal} close={() => setIsEditCommentModal(false)} mutate={mutate} id={id} />
      {/* 有的 */}
      <Modal
        title={i18n.t('prompt')}
        open={isModalOpenDeleteMemo}
        onOk={handleOkDelete}
        onCancel={handleCancelDelete}
        okText={i18n.t('confirm')}
        cancelText={i18n.t('cancel')}
        centered
      >
        <p>{i18n.t('confirmDelete')}</p>
      </Modal>

    </>
  )
}

export const MemberLevelSelect = ({ name, form, mode, disabled, bonusData, all = true }: {
  name: any;
  form: any
  mode?: any
  disabled?: boolean;
  bonusData?: any;
  all?: boolean;
}) => {
  const { data } = $get({ url: 'api/memberlevel/list' });
  const $s = (val: any) => mode === 'multiple' ? val?.sort((a: number, b: number) => a - b) : val

  return (
    <Select
      value={$s(form.getFieldValue(name))}
      mode={mode}
      disabled={disabled}
      placeholder={mode === 'multiple' ? i18n.t('pleaseSelectMemberLevel') : i18n.t('memberLevel')}
      className="w-full"
      options={
        all
          ? [{ value: '', label: i18n.t('ALL') },
          ...(bonusData || data?.Data || []).map((item: any) => (
            { value: item.Id, label: item.Name }
          ))
          ].filter(item => !(mode === 'multiple' && item.value === ''))
          : [...(bonusData || data?.Data || []).map((item: any) => (
            { value: item.Id, label: item.Name }
          ))
          ].filter(item => !(mode === 'multiple' && item.value === ''))
      }
      onChange={val => form.setFieldValue(name, $s(val))}
    />
  )
}

export const GameCategorySelect = ({ name, form, mode, disabled, code }: {
  name: any;
  form: any
  mode?: any
  disabled?: boolean;
  code?: boolean;
}) => {
  const { data } = $get({ url: 'api/category/list', params: { Status: 1 } })
  const $s = (val: any) => mode === 'multiple' ? val?.sort((a: number, b: number) => a - b) : val

  return (
    <Select
      value={$s(form.getFieldValue(name))}
      mode={mode}
      disabled={disabled}
      placeholder={i18n.t("gameCategory")}
      className="w-full"
      options={[
        { value: '', label: i18n.t('allGameCategory') },
        ...(data?.Data || []).map((item: any) => (
          { value: code ? item.Code : item.Id, label: i18n.t(item.Name) }
        ))
      ].filter(item => !(mode === 'multiple' && item.value === ''))}
      onChange={val => form.setFieldValue(name, $s(val))}
    />
  )
}

// 會員標籤: 新增
export const PopupAddMemberTag = ({ isOpen, close, mutate }: any) => {
  const [form] = useForm();
  const [showTextColor, setShowTextColor] = useState('#FFFFFF');
  const [showColor, setShowColor] = useState('#000000');
  const [title, setTitle] = useState<string>('');
  const [showTitle, setShowTitle] = useState(true);

  const onTextColorChange = (hex: string) => {
    setShowTextColor(hex);
    form.setFieldValue('textColor', hex);
  }

  const onColorChange = (hex: string) => {
    setShowColor(hex);
    form.setFieldValue('color', hex);
  }

  const onFinish = (formData: any) => {
    $post({
      url: 'api/tag/member/create',
      send: {
        Name: formData.tagName,
        IsShowName: formData.isShowTagName ? 1 : 0,
        TextColor: formData.textColor,
        Color: formData.color,
        Description: formData.description,
        Position: formData.sort,
        Status: formData.status
      },
      success: () => {
        message.success(i18n.t('addSuccess'));
        onClose();
        mutate();
      },
      resCode: RESPONSE_CODE_TAG
    })
  }

  const onClose = () => {
    close();
    form.resetFields();
    setShowTextColor('#FFFFFF');
    setShowColor('#000000');
    setShowTitle(true);
    setTitle('');
  }

  return (
    <Modal
      title={i18n.t('add')}
      open={isOpen}
      onCancel={onClose}
      centered
      width={450}
      footer={false}
      forceRender
    >
      <Form layout="vertical" form={form} onFinish={onFinish} initialValues={{
        tagName: null,
        isShowTagName: true,
        textColor: '#FFFFFF',
        color: '#000000',
        sort: null,
        description: null,
        status: 1
      }}>
        <Row gutter={[0, 10]}>
          <Col span={24}>
            <Form.Item name="tagName" label={
              <Row gutter={12}>
                <Col>{i18n.t('tagName')}</Col>
                <Col>
                  <Tag className="center"
                    style={
                      showTextColor || showColor
                        ? {
                          color: showTextColor,
                          background: showColor,
                          minWidth: 80,
                          minHeight: 25
                        } : {}
                    }>
                    {showTitle && title ? title : (showTitle) ? i18n.t('tagName') : ''}
                  </Tag>
                </Col>
              </Row>
            } rules={[{ required: true, message: `${i18n.t('inputData')}` }]}>
              <Input placeholder={`${i18n.t('inputData')}`} onChange={e => setTitle(e.target.value)}
                maxLength={20} showCount />
            </Form.Item>
            <Form.Item name="isShowTagName" valuePropName="checked"
              rules={[{ required: true, message: `${i18n.t('inputData')}` }]}>
              <Checkbox onClick={() => setShowTitle(!showTitle)}>
                {i18n.t('showTagName')}
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="textColor" label={i18n.t('tagFontColor')}
              rules={[{ required: true, message: `${i18n.t('inputData')}` }]}>
              <ColorPicker className="w-full" onChange={e => onTextColorChange(e.toHexString())} showText />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="color" label={i18n.t('tagBackgroundColor')}
              rules={[{ required: true, message: `${i18n.t('inputData')}` }]}>
              <ColorPicker className="w-full" onChange={e => onColorChange(e.toHexString())} showText />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="sort" label={i18n.t('sort')}  rules={[...verify({ point: 0 }), { max: 99, type: 'number', message: `${i18n.t('maximumValueIs99')}` }]}>
              <InputNumber className="w-full" placeholder={`${i18n.t('inputData')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <LayoutTextArea formName='description' formLabel={i18n.t('description')}
              formRules={[{ type: 'string', max: 200 }]} maxLength={200} />
          </Col>
          <Col span={24}>
            <Form.Item name="status" label={i18n.t('status')} required
              rules={[{ required: true, message: `${i18n.t('inputData')}` }]}>
              <Select options={common.enumToOptions(ENABLE)} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Row justify="center" gutter={[12, 12]}>
              <Col><Button onClick={onClose}>{i18n.t('cancel')}</Button></Col>
              <Col><Button type="primary" htmlType="submit">{i18n.t('confirm')}</Button></Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal >
  )
}
// 會員標籤: 編輯
export const PopupEditMemberTag = ({ isOpen, close, id, mutate, refresh, setRefresh }: any) => {
  const { permissions: $p } = useAccount();
  const [form] = useForm();
  const [showTextColor, setShowTextColor] = useState('#FFFFFF');
  const [showColor, setShowColor] = useState('#000000');
  const [title, setTitle] = useState<string>('');
  const [showTitle, setShowTitle] = useState(true);

  const { data: TagInfo, mutate: mutateOne, isValidating } = $get({
    url: 'api/tag/one',
    params: { Id: id },
    allow: !!id
  })

  useEffect(() => {
    if (isOpen) mutateOne()
  }, [isOpen]);

  useEffect(() => {
    if (TagInfo && TagInfo.Data && isOpen) {
      form.setFieldsValue({
        tagName: TagInfo.Data.Name,
        isShowTagName: TagInfo.Data.IsShowName,
        textColor: TagInfo.Data.TextColor,
        color: TagInfo.Data.Color,
        sort: TagInfo.Data.Position,
        description: TagInfo.Data.Description,
        status: TagInfo.Data.Status
      })
      setShowTextColor(TagInfo.Data.TextColor);
      setShowColor(TagInfo.Data.Color);
      setTitle(TagInfo.Data.Name);
      setShowTitle(TagInfo.Data.IsShowName);
    }
  }, [TagInfo, isOpen]);

  const onTextColorChange = (hex: string) => {
    setShowTextColor(hex);
    form.setFieldValue('textColor', hex);
  }

  const onColorChange = (hex: string) => {
    setShowColor(hex);
    form.setFieldValue('color', hex);
  }


  const onFinish = (formData: any) => {
    $post({
      url: 'api/tag/member/update',
      send: {
        Id: id,
        Name: formData.tagName,
        IsShowName: formData.isShowTagName ? 1 : 0,
        TextColor: formData.textColor,
        Color: formData.color,
        Description: formData.description,
        Position: formData.sort,
        Status: formData.status
      },
      success: () => {
        message.success(i18n.t('editSuccess'));
        onClose();
        mutate();
        setRefresh(!refresh);
      },
      resCode: RESPONSE_CODE_TAG
    })
  }

  const onClose = () => {
    close();
    form.resetFields();
  }
  const $editPermissions = $p('11403');

  return (
    <Modal
      title={i18n.t('detail')}
      open={isOpen}
      onCancel={onClose}
      centered
      width={450}
      footer={false}
      forceRender
    >
      <Spin spinning={isValidating}>
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Row gutter={[0, 10]}>
            <Col span={24}>
              <Form.Item name="tagName" label={
                <Row gutter={12}>
                  <Col>{i18n.t('tagName')}</Col>
                  <Col>
                    <Tag className="center"
                      style={
                        showTextColor || showColor
                          ? {
                            color: showTextColor,
                            background: showColor,
                            minWidth: 80,
                            minHeight: 25
                          } : {}
                      }>
                      {showTitle && title ? title : (showTitle) ? i18n.t('tagName') : ''}
                    </Tag>
                  </Col>
                </Row>
              } rules={[{ required: true, message: `${i18n.t('inputData')}` }]}>
                <Input
                  placeholder={`${i18n.t('tagName')}`}
                  maxLength={20}
                  showCount
                  onChange={e => setTitle(e.target.value)}
                  disabled={!$editPermissions}
                />
              </Form.Item>
              <Form.Item name="isShowTagName" valuePropName="checked" rules={[{ required: true, message: `${i18n.t('inputData')}` }]}>
                <Checkbox onClick={() => setShowTitle(!showTitle)} disabled={!$editPermissions}>
                  {i18n.t('showTagName')}
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="textColor" label={i18n.t('tagFontColor')} rules={[{ required: true, message: `${i18n.t('inputData')}` }]}>
                <ColorPicker className="w-full" onChange={e => onTextColorChange(e.toHexString())} showText
                  disabled={!$editPermissions} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="color" label={i18n.t('tagBackgroundColor')} rules={[{ required: true, message: `${i18n.t('inputData')}` }]}>
                <ColorPicker className="w-full" onChange={e => onColorChange(e.toHexString())} showText
                  disabled={!$editPermissions} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="sort" label={i18n.t('sort')} rules={[...verify({ point: 0 }), { max: 99, type: 'number', message: `${i18n.t('maximumValueIs99')}` }]}>
                <InputNumber className="w-full" placeholder={`${i18n.t('inputData')}`} disabled={!$editPermissions} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="description" label={i18n.t('description')}
                rules={[{ type: 'string', max: 200 }]}>
                <Input.TextArea
                  size="middle"
                  placeholder={`${i18n.t('inputData')}`}
                  maxLength={200}
                  autoSize={{ minRows: 2, maxRows: 1 }}
                  showCount
                  disabled={!$editPermissions}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="status" label={i18n.t('status')} rules={[{ required: true, message: `${i18n.t('inputData')}` }]}>
                <Select options={common.enumToOptions(ENABLE)} disabled={!$editPermissions} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Row justify="center" gutter={[12, 12]}>
                <Col><Button onClick={onClose}>{i18n.t('close')}</Button></Col>
                {
                  $p('11403') &&
                  <Col><Button type="primary" htmlType="submit">{i18n.t('confirm')}</Button></Col>
                }
              </Row>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal >
  )
}
// 會員標籤: 會員帳號列表
export const PopupMemberTagList = ({ isOpen, close, id, mutate, refresh }: any) => {
  const { permissions: $p } = useAccount();
  const [input, setInput] = useState('');
  const [isOpenDismissModal, setIsOpenDismissModal] = useState(false);
  const [selected, setSelected] = useState<any[]>([]);
  const [memberId, setMemberId] = useState<number | null>(null);
  const [page, setPage] = useState<number[]>([1, 10]);
  const { data: MemberList, isValidating, mutate: mutateMember } = $get({
    url: 'api/member/tags/list',
    params: {
      TagsId: id,
      PageIndex: page[0],
      PageSize: page[1]
    },
    allow: !!id
  });

  useEffect(() => {
    mutateMember();
  }, [refresh]);

  const onSelectChange = (selectedKey: React.Key[], selectedRecord: any) => {
    setSelected(selectedRecord);
  };

  const onSetMemberTag = () => {
    if (input.includes(',')) return message.error(i18n.t('formatIncorrect'))
    $post({
      url: 'api/member/add/tag',
      send: {
        Account: input,
        Tag: id,
      },
      success: () => {
        message.success(i18n.t('addSuccess'));
        mutate();
        mutateMember();
        setInput('');
      },
      resCode: RESPONSE_CODE_TAG
    })
  }

  const onDelete = async (id: number) => {
    setIsOpenDismissModal(true);
    setMemberId(id);
  }

  const onClose = () => {
    close();
    setInput('');
    setSelected([]);
  }

  const onDeleteRefresh = () => {
    setSelected([]);
    mutate();
    mutateMember();
  }

  const $editPermissions = $p('11404');

  return (
    <Modal
      title={
        <Row align="middle" gutter={[12, 12]}>
          <Col style={{ fontSize: 16 }}>{i18n.t('memberAccount')}</Col>
        </Row>
      }
      open={isOpen}
      onCancel={onClose}
      width={500}
      centered
      footer={
        <Row justify="center">
          <Button onClick={onClose}>{i18n.t('close')}</Button>
        </Row>
      }
    >
      <Spin spinning={isValidating}>
        <Row gutter={[12, 12]}>
          {/* 總計 */}
          <Col span={24}>
            <Row align="middle">
              <Col span={12}>
                {i18n.t('tagMember')} : {MemberList?.TotalRecord || 0}
              </Col>
              <Col span={12}>
                {i18n.t('memberTotal')} : {MemberList?.MemberTotal || 0}
              </Col>
            </Row>
          </Col>
          {/* 設定標籤 */}
          <Col span={24}>
            <Row align="middle" justify="space-between">
              <Col span={18}>
                <Input
                  placeholder={`${i18n.t('memberAccount')}`}
                  value={input}
                  onChange={e => setInput(e.target.value)}
                  disabled={!$editPermissions}
                />
              </Col>
              <Col span={5}>
                <Button disabled={!input || !$editPermissions} onClick={onSetMemberTag} >
                  {i18n.t('setTag')}
                </Button>
              </Col>
            </Row>
          </Col>
          {/* 批次解除 */}
          <Col span={24}>
            <Button
              type="primary" danger
              disabled={selected.length === 0 || !$editPermissions}
              onClick={() => setIsOpenDismissModal(true)}
            >
              {i18n.t('batchRelease')}
            </Button>
          </Col>
          {/* 會員列表 */}
          <Col span={24}>
            <Table
              size="small"
              rowSelection={{
                selectedRowKeys: selected.map(item => item.key),
                onChange: onSelectChange,
              }}
              dataSource={
                MemberList?.Data.map((item: AgentTagInfo) => ({ key: item.Id, ...item })) || []
              }
              columns={[
                {
                  title: i18n.t('memberAccount'),
                  dataIndex: 'Account',
                },
                {
                  title: i18n.t('operation'),
                  width: 100,
                  render: (_, record) =>
                    <Button
                      className="size-12"
                      type="link"
                      onClick={() => onDelete(record.Id)}
                      disabled={selected.length !== 0 || !$editPermissions}
                    >
                      {i18n.t('removeTag')}
                    </Button>
                },
              ]}
              pagination={false}
            />
            <LayoutPagination total={MemberList?.TotalRecord || 0} setPage={setPage} page={page} defaultPageSize={5} />
          </Col>
        </Row>
      </Spin>
      <PopupDismissTag isOpen={isOpenDismissModal} close={() => setIsOpenDismissModal(false)}
        selected={selected} memberId={memberId} tagId={id} mutate={onDeleteRefresh} />
    </Modal >
  )
}
// 會員標籤: 批次解除詢問
export const PopupDismissTag = ({ isOpen, close, selected, memberId, tagId, mutate }: any) => {
  const [loading, setLoading] = useState(false);

  const onDelete = () => {
    if (loading) return;

    if (selected?.length !== 0) {
      setLoading(true);
      // 批次解除
      selected?.map((record: any) => (
        $post({
          url: 'api/member/delete/batch/tag',
          send: {
            Id: record.Id,
            Tag: tagId,
          },
          resCode: RESPONSE_CODE_TAG
        })
      ))
      setTimeout(() => {
        setLoading(false);
        message.success(i18n.t('operationSuccess'));
        close();
        mutate();
      }, 1000);
    } else {
      // 單一解除
      $post({
        url: 'api/member/delete/one/tag',
        send: {
          Id: memberId,
          Tag: tagId,
        },
        success: () => {
          message.success(i18n.t('operationSuccess'));
          close();
          mutate();
        },
        resCode: RESPONSE_CODE_TAG
      })
    }
  }

  return (
    <Modal
      title={
        <Row gutter={15} align="middle">
          <Image className="center" src={hintIcon} width={16} height={16} preview={false} />
          <Col className="size-16">{i18n.t('prompt')}</Col>
        </Row>
      }
      open={isOpen}
      onCancel={close}
      centered
      width={450}
      footer={
        <Row justify="center">
          <Button className="mt-1" onClick={close} disabled={loading}>{i18n.t('cancel')}</Button>
          <Button className="mt-1 ml-1" type="primary" onClick={onDelete} disabled={loading}>{i18n.t('confirm')}</Button>
        </Row>
      }
    >
      <Spin spinning={loading}>
        <Space className="mt-1" direction="vertical">
          <div>{selected?.length !== 0 ? `${i18n.t('confirmBatchRelease')} ${selected?.length} ${i18n.t('memberTagInBatch')}?` : `${i18n.t('confirmReleaseMemberTag')} ?`}</div>
        </Space>
      </Spin>
    </Modal>
  )
}
