export enum SMS_ROLE {
  member = 0,
  agent = 1,
}

export enum OPERATION_ACCOUNT_ROLE {
  member = 0,
  agent = 1,
}

// 換線方式
export enum CHANGE_MEMBER_METHOD {
  memberAccount = 0,
  allMembersDirectlyUnderTheLine = 1,
}
