import { Button, Col, Form, Row, Select, Spin } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useForm } from 'antd/lib/form/Form';
import { DatePickerCol, LayoutNav, LayoutPagination } from 'components/layout.component';
import { ViewPromotionList } from 'components/promotion.component';
import dayjs from 'dayjs';
import { PROMOTION_CATEGORY, PROMOTION_STATE } from 'enum/promotion';
import { PROMOTION_TIME } from 'enum/state';
import useAccount from 'hooks/account.hook';
import i18n from 'i18n';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { $get } from 'services';
import { enumToOptions, selectFuzzyFilter, timeL2S } from "utils/common";

// 優惠活動管理

interface SearchParams {
  Name?: string;
  Category?: number;
  Type?: number;
  Status?: number;
  TimeCondition?: number;
  StartDate?: string;
  EndDate?: string;
  DateType?: number | undefined;
  PageSize?: number;
  PageIndex?: number;
}

interface PromotionType {
  Id: number,
  Name: string
}

const PageMain: React.FC = () => {
  const navigate = useNavigate();
  const { permissions: $p } = useAccount();
  const [form] = useForm();
  const [page, setPage] = useState<number[]>([1, 10]);
  const [reset, setReset] = useState(false);
  const [date, setDate] = useState([
    dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
  ]);
  const [params, setParams] = useState<SearchParams>({
    TimeCondition: PROMOTION_TIME.eventTime,
    StartDate: timeL2S(date[0]),
    EndDate: timeL2S(date[1]),
  })
  const { data: BonusOptions, isValidating: optionLoading } = $get({ url: 'api/bonus/name/list' })
  const { data: promotion, mutate, isValidating } = $get({
    url: 'api/bonus/list',
    params: {
      ...params,
      PageSize: page[1],
      PageIndex: page[0],
    },
  })
  const onFinish = (formData: any) => {
    setPage([1, page[1]]);
    const selectedOption = BonusOptions?.Data.find((option: any) => option.Id === formData.promotionName);
    const promotionName = selectedOption ? selectedOption.Name : '';
    if (date) {
      setParams({
        ...params,
        Name: promotionName,
        Category: formData.category,
        Status: formData.status,
        TimeCondition: formData.TimeCondition,
        StartDate: timeL2S(date[0]),
        EndDate: timeL2S(date[1])
      })
    }
    mutate();
  }

  const onClear = () => {
    form.resetFields();
    setReset(!reset);
    setPage([1, 10]);
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content className="ph-2 pt-1">
        <Form form={form} onFinish={onFinish} initialValues={{
          TimeCondition: PROMOTION_TIME.eventTime,
        }}>
          <Row gutter={[12, 12]} className="pb-1">
            <Col>
              <Form.Item className="w-12" name="promotionName">
                <Select
                  showSearch
                  loading={optionLoading}
                  placeholder={i18n.t('promotionName')}
                  filterOption={selectFuzzyFilter}
                  options={
                    BonusOptions?.Data.length > 0
                    ? [
                      { value: '', label: i18n.t('ALL') },
                      ...BonusOptions.Data.map((option: PromotionType) => (
                        { value: option.Id, label: option.Name }
                      )) 
                    ]
                    : [{ value: '', label: i18n.t('currentlyNoOngoingPromotions') }]
                  }
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item className="w-12" name="category">
                <Select
                  placeholder={i18n.t('category')}
                  options={[
                    { value: '', label: i18n.t('ALL') },
                    ...enumToOptions(PROMOTION_CATEGORY)
                  ]}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item className="w-12" name="status">
                <Select
                  placeholder={i18n.t('status')}
                  options={[
                    { value: '', label: i18n.t('ALL') },
                    ...enumToOptions(PROMOTION_STATE)
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[12, 12]} className="pb-1">
            <Col>
              <Form.Item className="w-12" name="TimeCondition">
                <Select options={enumToOptions(PROMOTION_TIME)} />
              </Form.Item>
            </Col>
            <DatePickerCol width={191} date={date} setDate={setDate} initDate={reset} />
            <Col>
              <Button type="primary" htmlType="submit">{i18n.t('search')}</Button>
            </Col>
            <Col>
              <Button onClick={onClear}>{i18n.t('clear')}</Button>
            </Col>
          </Row>
        </Form>
        <Row gutter={[16, 16]} className="pb-1">
          {
            $p('30102') && <>
              <Col><Button type="primary" onClick={() => navigate('/promotion/add-other')}>{i18n.t('add')}</Button></Col>
              <Col><Button type="primary" onClick={() => navigate('/promotion/add-mission')}>{i18n.t('dailyCheck')}</Button></Col>
            </>
          }
          {
            $p('30103') &&
            <Col><Button type="primary" onClick={() => navigate('/promotion/edit-recommend')}>{i18n.t('referralReward')}</Button></Col>
          }
          {
            $p('30103') && <>
              <Col><Button type="primary" onClick={() => navigate('/promotion/edit-signup-bonus')}>{i18n.t('signupBonus')}</Button></Col>
            </>
          }
          {/*TODO:等權限(全民代理設置查看)*/}
          {
            $p('30103') && <>
              <Col><Button type="primary" onClick={() => navigate('/people-agent')}>{i18n.t('allAgent')}</Button></Col>
            </>
          }
        </Row>
        {/* 列表 */}
        <Spin spinning={isValidating}>
          <ViewPromotionList data={promotion} mutate={mutate} />
          <LayoutPagination total={promotion ? promotion.TotalRecord : 0} page={page} setPage={setPage} />
        </Spin>
      </Content>
    </div>
  );
};

export default PageMain; 